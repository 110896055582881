import EventEmitter from 'events';
import axios, { CancelTokenSource } from 'axios';

export type Etag = string;

export class FileUploader extends EventEmitter {
  private cancelToken: CancelTokenSource | undefined;

  static isCancelError(error: unknown): boolean {
    return axios.isCancel(error)
  }

  constructor(
    public file: File,
    public uploadParts: {
      n: number,
      range: [number, number],
      uploadUrl: string
    }[],
  ) {
    super();
  }

  cancel() {
    if (this.cancelToken) {
      this.cancelToken.cancel('Canceled by user');
    }
  }

  async upload(): Promise<Etag[]> {
    const etags: Etag[] = [];
    for (const part of this.uploadParts) {
      const {
              range,
              uploadUrl,
            } = part;
      const filePart = this.file.slice(range[0], range[1]);
      this.cancelToken = axios.CancelToken.source();
      const response = await axios.put(uploadUrl, filePart, {
        cancelToken: this.cancelToken.token,
        onUploadProgress: (event: ProgressEvent) => {
          const loaded = range[0] + event.loaded;
          const internalProgressEvent = new ProgressEvent(event.type, {
            lengthComputable: true,
            loaded,
            total: this.file.size
          });
          this.emit('progress', internalProgressEvent);
        }
      });
      const etag = (response.headers.etag as string)
        .replace(/"/g, '');
      etags.push(etag);
    }
    return etags;
  }
}
