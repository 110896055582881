import { Context as InversifyContext } from '@redtea/react-inversify';
import { Container } from 'inversify';
import { Provider as MobxProvider } from 'mobx-react';
import React, { useDebugValue, useMemo } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { GLOBAL_IOC_TYPES } from './ioc/iocTypes';
import { ErrorPage } from './pages/error/ErrorPage';
import { GlobalErrorWatcher } from './pages/error/GlobalErrorWatcher';
import { OnboardingPage } from './pages/onboarding/OnboardingPage';
import { SandboxPage } from './pages/sandbox';
import { createProviderStateProps } from './states/utils';

function useStates(container: Container) {
  const states = useMemo(() => ({
    [GLOBAL_IOC_TYPES.states.GlobalError]: container.get(GLOBAL_IOC_TYPES.states.GlobalError),
    [GLOBAL_IOC_TYPES.states.Package]: container.get(GLOBAL_IOC_TYPES.states.Package),
  }), [container]);

  useDebugValue(states);

  return states;
}

const App: React.FC<{ container: Container }> = (props) => {
  const states = useStates(props.container);

  return (
    <InversifyContext.Provider value={props.container}>
      <Router history={props.container.get(GLOBAL_IOC_TYPES.browserHistory)}>
        <MobxProvider {...createProviderStateProps(states)}>
          <GlobalErrorWatcher/>
          <Switch>
            <Route exact path="/">
              <OnboardingPage/>
            </Route>
            <Route exact path="/error">
              <ErrorPage/>
            </Route>
            {
              process.env.NODE_ENV === 'development' ?
                <Route exact path="/sandbox">
                  <SandboxPage/>
                </Route>
                : null
            }
          </Switch>
        </MobxProvider>
      </Router>
    </InversifyContext.Provider>
  );
};

export default App;
