export function getOs(): 'windows' | 'macos' | 'linux' | 'unknown' {
  if (('navigator' in window)) {
    // @ts-ignore
    const platform = (navigator.userAgentData?.platform || navigator.platform)?.toLowerCase();

    if (platform.startsWith('win')) return 'windows';
    if (platform.startsWith('mac')) return 'macos';
    if (platform.startsWith('linux')) return 'linux';
  }

  return 'unknown';
}
