import React, { CSSProperties } from 'react';

type LayoutFrameProps = {
  style?: CSSProperties;
}

export const LayoutFrame: React.FC<LayoutFrameProps> = (props) => {
  return (
    <div style={{
      width: 1198,
      marginLeft: 'auto',
      marginRight: 'auto',
      ...props.style
    }}>
      {props.children}
    </div>
  );
};
