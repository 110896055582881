import React from 'react';
import { ErrorIcon } from '../../../../../icons/ErrorIcon';
import { SuccessIcon } from '../../../../../icons/SuccessIcon';
import { FileSimpleState } from '../../../../types';
import { Body } from './elements/body/Body';
import styles from './UploaderFile.module.css';

type UploaderFileProps = {
  file: FileSimpleState;
}

export const UploaderFile: React.FC<UploaderFileProps> = (props) => {
  const {
          file,
        } = props;

  return (
    <div className={styles.root}>
      <div className={styles.bodyContainer}>
        <Body
          file={file}
        />
      </div>
      <div className={styles.statusContainer}>
        {
          file.status === 'success' && (
            <SuccessIcon/>
          )
        }
        {
          file.status === 'failed' && (
            <ErrorIcon/>
          )
        }
      </div>
    </div>
  );
};
