export class RequestError extends Error {
  constructor(
    public statusCode: number,
    public message: string,
    public details?: Record<string, string>
  ) {
    super(message);
  }
}

export class RequestCancelError extends Error {
  constructor() {
    super('request is canceled');
  }
}
