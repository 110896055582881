// IoC container services map
export const GLOBAL_IOC_TYPES = {
    browserHistory: Symbol.for('browserHistory'),
    states: {
        GlobalError: Symbol.for('states.GlobalError'),
        Package: Symbol.for('states.Package'),
    },
    services: {
        ApiClientFactory: Symbol.for('services.ApiClientFactory'),
        WebDatClient: Symbol.for('services.WebDatClient'),
    }
};
