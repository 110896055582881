import { InternalFile } from '../../../types';
import { getFileExt } from './fileUtils';

export interface Options {
  extensions?: string[];
  allowExtensions?: string[];
  excludeExtensions?: string[];
  minImageSize?: number;
  maxFileSize?: number;
}

const RASTER_IMAGES_EXT = [
  'png',
  'jpg',
  'jpeg',
];

export function isFileAcceptable(file: InternalFile, opts: Options): Promise<boolean> {
  return new Promise(
    (resolve) => {
      const {
              size,
            } = file;

      if (typeof opts.maxFileSize === 'number') {
        if (size > opts.maxFileSize) {
          console.debug('size of %s greater than %d bytes', file.name, opts.maxFileSize);
          return false;
        }
      }

      const ext: string | null = getFileExt(file);

      if (!ext) {
        console.debug('%s has no extension', file.name);
        return resolve(false);
      }

      if (opts.extensions && !opts.extensions.includes(ext.toLowerCase())) {
        console.debug('extension of %s does not match allowed %s', file.name, opts.extensions.join(', '));
        return resolve(false);
      } else if (opts.excludeExtensions && opts.excludeExtensions.includes(ext.toLowerCase())) {
        console.debug('extension of %s is in excluded list %s', file.name, opts.excludeExtensions.join(', '));
        return resolve(false);
      }  else if (opts.allowExtensions && !opts.allowExtensions.includes(ext.toLowerCase())) {
        console.debug('extension of %s is not in allowed list %s', file.name, opts.allowExtensions.join(', '));
        return resolve(false);
      }

      if (typeof opts.minImageSize === 'number') {
        if (RASTER_IMAGES_EXT.includes(ext)) {
          const img = new Image();
          const url = URL.createObjectURL(file);

          img.src = url;

          img.onload = () => {
            const { width, height } = img;
            const longestSide = Math.max(width, height);
            if (longestSide < opts.minImageSize!) {
              console.debug(
                'the longest side of image %s does not match minimal %dpx. got sizes %dx%d',
                file.name, opts.minImageSize, img.width, img.height
              );
              return resolve(false);
            }
            URL.revokeObjectURL(url);
            resolve(true);
          };

          img.onerror = (event: any) => {
            URL.revokeObjectURL(url);
            console.error(event);
            resolve(false);
          };

          return;
        }
      }

      return resolve(true);
    },
  );

}
