// Reference: https://github.com/react-component/upload/blob/669223730a13762f6e3ad6dc9334e80dd1ee8200/src/traverseFileTree.ts
import { InternalFile } from '../../../types';

export interface InternalDataTransferItem extends DataTransferItem {
  isFile: boolean;
  file: (cd: (file: InternalFile) => void) => void;
  createReader: () => any;
  fullPath: string;
  isDirectory: boolean;
  name: string;
  path: string;
}

function loopFiles(item: InternalDataTransferItem): Promise<InternalDataTransferItem[]> {
  return new Promise(resolve => {
    const dirReader = item.createReader();
    let fileList: any = [];

    function sequence() {
      dirReader.readEntries((entries: InternalDataTransferItem[]) => {
        const entryList = Array.prototype.slice.apply(entries);
        fileList = fileList.concat(entryList);

        // Check if all the file has been viewed
        const isFinished = !entryList.length;

        if (isFinished) {
          resolve(fileList);
        } else {
          sequence();
        }
      });
    }

    sequence();
  });
}

export const traverseFileTree = async (files: InternalDataTransferItem[], isAccepted: (file: InternalFile) => boolean): Promise<InternalFile[]> => {
  const result: InternalFile[] = [];

  const _traverseFileTree = async (item: InternalDataTransferItem, path: string = '') => {
    item.path = path;

    if (item.isFile) {
      const filePromise = new Promise<void>(resolve => {
        item.file(file => {
          if (isAccepted(file)) {
            if (item.fullPath && !file.webkitRelativePath) {
              Object.defineProperties(file, {
                webkitRelativePath: {
                  writable: true,
                },
              });

              file.webkitRelativePath = item.fullPath.replace(/^\//, '');

              Object.defineProperties(file, {
                webkitRelativePath: {
                  writable: false,
                },
              });
            }

            result.push(file);
          }

          resolve();
        });
      });

      await filePromise;
    } else if (item.isDirectory) {
      const entries = await loopFiles(item);

      await Promise.all(
        entries.map(e => _traverseFileTree(e, `${path}${item.name}/`))
      );
    }
  };

  await Promise.all(
    files.map(f => _traverseFileTree(f.webkitGetAsEntry()))
  );

  return result;
};

