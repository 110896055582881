import { Input } from 'antd';
import React, { ChangeEvent, useCallback } from 'react';
import { CustomFormItem } from '../../custom-form-item';
import { MetadataFormFieldProps } from '../types';


export const TextField: React.FC<MetadataFormFieldProps> = (props) => {
  const {
          validationStatus,
          value,
          label,
          disabled,
          onChange,
          required
        } = props;

  const internalOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      onChange(e.target.value),
    [onChange],
  );

  return (
    <CustomFormItem
      label={label}
      validateStatus={validationStatus}
      required={required}
    >
      <Input
        disabled={disabled}
        value={value}
        onChange={internalOnChange}
        allowClear
      />
    </CustomFormItem>
  );
};
