import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal/Modal';
import classNames from 'classnames';
import { Icon } from '../../elements/icon/Icon';
import { mergeWithSharedConfig, SharedProps } from '../../shared';
import { ReactComponent as SVGIcon } from './elements/icon.svg';
import commonStyles from '../../styles.module.css';
import * as React from 'react';

export type BlockPopupProps = {
  message?: string | React.ReactNode;
  title?: string;
  modal?: ModalFuncProps;
} & SharedProps;

export function blockPopup(props?: BlockPopupProps) {
  return Modal.success(mergeWithSharedConfig({
    title: props?.title ?? 'Error',
    content: props?.message ?? null,
    closable: false,
    maskClosable: false,
    className: classNames(commonStyles.root),
    okButtonProps: {},
    onOk: props?.onCancel,
    okType: 'danger',
    okText: 'Cancel',
    icon: <Icon><SVGIcon/></Icon>,
    ...props?.modal
  }));
}
