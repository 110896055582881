import { Space } from 'antd';
import React, { useCallback, useMemo } from 'react';
import path from 'path';
import { FileState } from '../../types';
import { UploaderFile } from './elements/file/UploaderFile';
import { UploaderFolder } from './elements/folder/UploaderFolder';
import styles from './UploaderFileList.module.css';

type UploaderFileListProps = {
  files: FileState[];
  onCancel(file: FileState): void;
  onRemove(file: FileState): void;
  onRetry(file: FileState): void;
}

type Files = {
  folders: {
    title: string;
    files: FileState[];
  }[];
  files: FileState[];
}

export const UploaderFileList: React.FC<UploaderFileListProps> = (props) => {
  const {
    files,
    onCancel,
    onRemove,
    onRetry
  } = props;

  const onAction = useCallback(
    (file: FileState, action: (f: FileState) => void) => () => action(file),
    []
  );

  const internalFiles: Files = useMemo(
    () => {
      const folders: Map<string, FileState[]> = new Map();
      const _files: FileState[] = [];

      for (const file of files) {
        const relativePath = file.file.webkitRelativePath;
        if (relativePath && relativePath !== file.file.name) {
          const title = relativePath.split(path.sep)[0];
          if (!title) {
            _files.push(file);
            continue;
          }
          const target = folders.get(title) || [];
          target.push(file);
          folders.set(title, target);
          continue;
        }
        _files.push(file);
      }

      return {
        folders: Array.from(folders.entries()).map(([title, filesList]) => ({
          title,
          files: filesList
        })),
        files: _files
      };
    },
    [files]
  );

  if (files.length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Space direction="vertical" size={24} style={{width: '100%'}}>
        {
          internalFiles.folders.length !== 0 && (
            internalFiles.folders.map(folder => (
              <UploaderFolder
                key={folder.title}
                title={folder.title}
              >
                {
                  folder.files.map(file => (
                    <UploaderFile
                      key={file.key}
                      file={file}
                      onCancel={onAction(file, onCancel)}
                      onRemove={onAction(file, onRemove)}
                      onRetry={onAction(file, onRetry)}
                    />
                  ))
                }
              </UploaderFolder>
            ))
          )
        }
        {
          internalFiles.files.length !== 0 && (
            <ul className={styles.list}>
              {
                internalFiles.files.map(file => (
                  <li key={file.key}>
                    <UploaderFile
                      file={file}
                      onCancel={onAction(file, onCancel)}
                      onRemove={onAction(file, onRemove)}
                      onRetry={onAction(file, onRetry)}
                    />
                  </li>
                ))
              }
            </ul>
          )
        }
      </Space>
    </div>
  );
};
