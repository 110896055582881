import { DatePicker, Form, FormItemProps, Input, Select } from 'antd';
import classNames from 'classnames';
import { Moment } from 'moment';
import { nanoid } from 'nanoid';
import React, { cloneElement, useMemo, useRef, useState } from 'react';
import * as reactIs from 'react-is';
import styles from './CustomFormItem.module.css';

export type CustomFormItemProps = Pick<FormItemProps,
  'label'
  | 'id'
  | 'validateStatus'
  | 'hasFeedback'
  | 'help'> & { required?: boolean };

export const CustomFormItem: React.FC<CustomFormItemProps> = (props) => {
  const {
          label,
          id,
          validateStatus,
          children,
          hasFeedback,
          required,
        } = props;

  const [, setChanged] = useState<number>(-1);
  const hasValue = useRef<boolean>(false);
  const hasSecondValue = useRef<boolean>(false);
  const valid = useRef<boolean>(true);

  const internalId = useMemo(() => {
    const childId: string | undefined = (children as any)?.props?.id;

    if (childId) {
      return childId;
    }

    if (!id) {
      return nanoid();
    }
    return id;
  }, [id, children]);

  const child = useMemo<{ known: boolean, el: any }>(() => {
    const source = React.Children.only(children);

    if (reactIs.isElement(source)) {
      const type: any = (source as any).type;

      if (type === DatePicker) {
        const cloned = cloneElement(source, {
          id: internalId,
          placeholder:
            label ?
              null
              : source.props.placeholder
          ,
          inputReadOnly: true,
          onChange: (date: Moment | null, dateStr: string) => {
            setChanged(Date.now());
            hasValue.current = true;

            if (source.props.onChange) {
              source.props.onChange(date, dateStr);
            }
          },
        });

        if (source.props.hasOwnProperty('value')) {
          hasValue.current = source.props.value !== null;
        }

        return {
          known: true,
          el: cloned,
        };
      }

      if (type === Input || type === Input.TextArea) {
        const nextProps: Record<string, any> = {
          id: internalId,
          placeholder:
            label ?
              null
              : source.props.placeholder
          ,
          onInput: (event: any) => {
            hasValue.current = event.target.value !== '';
            valid.current = event.target.validity.valid;
            setChanged(Date.now());

            if (source.props.onInput) {
              source.props.onInput(event);
            }
          },
        };

        if (source.props.hasOwnProperty('value')) {
          hasValue.current = source.props.value !== null
            && source.props.value !== undefined
            && source.props.value !== '';
        }

        const cloned = cloneElement(source, nextProps);

        return {
          known: true,
          el: cloned,
        };
      }

      if (type === Select) {
        const nextProps: Record<string, any> = {
          id: internalId,
          placeholder:
            label ?
              null
              : source.props.placeholder
          ,
          onChange: (value: any | any[], option: any | any[]) => {
            setChanged(Date.now());
            hasValue.current = Array.isArray(value) ?
              value.length > 0
              : !!value;
            hasSecondValue.current = false;

            if (source.props.onChange) {
              source.props.onChange(value, option);
            }
          },
          showSearch: true,
          onSearch(value: any) {
            setChanged(Date.now());
            hasSecondValue.current = !!value;

            if (source.props.onSearch) {
              source.props.onSearch(value);
            }
          },
        };

        if (source.props.hasOwnProperty('value')) {
          hasValue.current = Array.isArray(source.props.value) ?
            source.props.value.length > 0
            : !!source.props.value;
        }

        const cloned = cloneElement(source, nextProps);

        return {
          known: true,
          el: cloned,
        };
      }
    }

    return {
      known: false,
      el: source,
    };
  }, [
    label,
    children,
    internalId,
    hasValue,
    hasSecondValue,
    setChanged,
    valid,
  ]);

  return (
    <Form.Item
      validateStatus={validateStatus}
      hasFeedback={hasFeedback}
      className={classNames(styles.customFormItem, {
        [styles.hasValue]: hasValue.current || hasSecondValue.current || !valid.current,
      })}
    >
      {
        !!label && child.known ?
          <label
            htmlFor={internalId}
            className={classNames(styles.label, {})}
          >{required ? <span style={{color: '#ff4d4f', marginRight: 2}}>*</span> : ''}{label}</label>
          : null
      }
      {child.el}
    </Form.Item>
  );
};
