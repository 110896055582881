import { Button } from 'antd';
import React, { useMemo } from 'react';
import { UploaderFileList } from './elements/files-list/UploaderFileList';
import { InfoPanel } from './elements/info-panel/InfoPanel';
import { FileSimpleState } from './types';
import styles from './UploaderSubmit.module.css';

interface NormalizedExtensions {
  extensions?: string[];
  allowExtensions?: string[];
  excludeExtensions?: string[];
}

export type UploaderSubmitProps = {
  allowFolders?: boolean;
  maxFiles?: number;
  minFiles?: number;
  extensions?: string[];
  allowExtensions?: string[];
  excludeExtensions?: string[];
  minImageSize?: number;
  attachmentsEmail?: string;
  attachmentsName?: string;
  disabled?: boolean;
  value: FileSimpleState[];
  showProgress?: boolean;
  // From 0 to 100
  progress?: number;
  onSubmit(): void;
}

export const UploaderSubmit: React.FC<UploaderSubmitProps> = (props) => {
  const {
          allowFolders,
          minImageSize,
          extensions,
          allowExtensions,
          excludeExtensions,
          value,
          onSubmit,
          disabled,
          maxFiles,
          minFiles,
          attachmentsEmail,
          attachmentsName
        } = props;

  const normalizedExtensions: NormalizedExtensions = useMemo(
    () => ({
      extensions: extensions && extensions.map(e => e.toLowerCase()),
      allowExtensions: allowExtensions && allowExtensions.map(e => e.toLowerCase()),
      excludeExtensions: excludeExtensions && excludeExtensions.map(e => e.toLowerCase()),
    }),
    [extensions, allowExtensions, excludeExtensions],
  );

  return (
    <div>
      <InfoPanel
        extensions={normalizedExtensions.extensions}
        allowExtensions={normalizedExtensions.allowExtensions}
        excludeExtensions={normalizedExtensions.excludeExtensions}
        minImageSize={minImageSize}
        allowFolders={allowFolders}
        attachmentsEmail={attachmentsEmail}
        attachmentsName={attachmentsName}
        maxFiles={maxFiles}
        minFiles={minFiles}
      />
      <UploaderFileList
        files={value}
      />
      <div className={styles.footer}>
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={disabled}
        >Upload</Button>
      </div>
    </div>
  );
};
