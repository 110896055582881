import { FileState, InternalFile } from '../../../types';
import { generateFileInitialState } from './generateFileInitialState';
import { isFileAcceptable } from './isFileAcceptable';

export interface Options {
  extensions?: string[];
  allowExtensions?: string[];
  excludeExtensions?: string[];
  minImageSize?: number;
  maxFileSize?: number;
  maxFiles?: number;
}

export async function prepareFileStates(files: InternalFile[], opts: Options): Promise<FileState[]> {
  if (typeof opts.maxFiles === 'number') {
    files = files.slice(0, opts.maxFiles);
  }

  const result: FileState[] = [];

  await Promise.all(
    files.slice().map(async (file) => {
      const accepted = await isFileAcceptable(file, {
        minImageSize: opts.minImageSize,
        extensions: opts.extensions,
        allowExtensions: opts.allowExtensions,
        excludeExtensions: opts.excludeExtensions,
        maxFileSize: opts.maxFileSize,
      });

      if (accepted) {
        result.push(
          generateFileInitialState(file),
        );
      }
    }),
  );

  return result;
}
