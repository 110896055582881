import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { GLOBAL_IOC_TYPES } from '../../ioc/iocTypes';
import { GlobalErrorState } from '../../states/GlobalErrorState';
import { useMobXState } from '../../states/utils';

export const GlobalErrorWatcher: React.FC = observer((props) => {
  const errorState = useMobXState<GlobalErrorState>(GLOBAL_IOC_TYPES.states.GlobalError);
  const routerHistory = useHistory();
  const location = useLocation();
  const prevLocationRef = useRef<string>(location.pathname);

  useEffect(() => {
    if (location.pathname === '/error') {
      return;
    }

    if (location.pathname !== prevLocationRef.current) {
      const prev = prevLocationRef.current;
      prevLocationRef.current = location.pathname;
      if (prev === '/error') {
        errorState.clearError();
        return;
      }
    }

    if (errorState.hasError) {
      routerHistory.push('/error');
    }
  }, [
    errorState,
    errorState.hasError,
    location.pathname,
    routerHistory,
    prevLocationRef,
  ]);

  return null;
});
