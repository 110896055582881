import { InternalFile } from '../../../types';

export function getFileExt(file: InternalFile): string | null {
  const parts = file.name.split('.');
  return parts.length > 1 ?
    parts[parts.length - 1] ?
      parts[parts.length - 1].toLowerCase()
      : null
    : null;
}
