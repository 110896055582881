import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useCallback } from 'react';
import { CustomFormItem } from '../../custom-form-item';
import { MetadataFormFieldProps } from '../types';
import styles from './YesNoField.module.css';


export const YesNoField: React.FC<MetadataFormFieldProps> = (props) => {
  const {
          value,
          label,
          disabled,
          onChange,
          required
        } = props;

  const internalOnChange = useCallback(
    (e: CheckboxChangeEvent) =>
      onChange(e.target.checked),
    [onChange],
  );

  return (
    <CustomFormItem required={required}>
      <Checkbox
        checked={value}
        disabled={disabled}
        onChange={internalOnChange}
        className={styles.checkbox}
      >{label}</Checkbox>
    </CustomFormItem>
  );
};
