import { Col, Form, Row } from 'antd';
import update from 'immutability-helper';
import React, { useCallback, useMemo } from 'react';
import { DateField } from './elements/DateField';
import { DropdownField } from './elements/DropdownField';
import { FloatField } from './elements/FloatField';
import { IntegerField } from './elements/IntegerField';
import { MetadataFormHeader } from './elements/metadata-form-header/MetadataFormHeader';
import { TagCloudField } from './elements/TagCloudField';
import { TextAreaField } from './elements/TextAreaField';
import { TextField } from './elements/TextField';
import { UrlField } from './elements/UrlField';
import { YesNoField } from './elements/YesNoField';
import styles from './MetadataForm.module.css';
import {
  MetadataFormField,
  MetadataFormFieldProps,
  MetadataFormFieldType,
  MetadataFormFieldValidationStatus,
} from './types';

const fieldComponentMap: Record<MetadataFormFieldType, React.FC<MetadataFormFieldProps>> = {
  dropdown: DropdownField,
  integer: IntegerField,
  float: FloatField,
  text: TextField,
  url: UrlField,
  date: DateField,
  tagcloud: TagCloudField,
  textarea: TextAreaField,
  yesno: YesNoField,
};

export type MetadataFormProps<V extends Record<string, any> = Record<string, any>> = {
  title: string;
  value: Record<string, any>;
  fields: MetadataFormField[];
  onChange(value: V, changed: keyof V): void;
}

export const MetadataForm: React.FC<MetadataFormProps> = (props) => {
  const {
          onChange,
          value,
          fields,
          title,
        } = props;

  const validationStatus = useMemo<MetadataFormFieldValidationStatus>(
    () => {
      let allSuccess = true;

      if (fields.length > 0) {
        for (const field of fields) {
          if (field.validationStatus === 'error') {
            return 'error';
          }

          if (field.validationStatus !== 'success') {
            allSuccess = false;
          }
        }

        if (allSuccess) {
          return 'success';
        }
      }

      return '';
    },
    [fields],
  );

  const internalOnChange = useCallback(
    (field: string) => (provideValue: any) => {
      const nextValue = update(value, {
        [field]: {
          $set: provideValue,
        },
      });

      onChange(nextValue, field);
    },
    [
      value,
      onChange,
    ],
  );

  const fieldColumns = useMemo(
    () => {
      const columns: (JSX.Element | null)[][] = [[],[]];
      const max = Math.ceil(fields.length / 2);
      let current = columns[0];
      for (const field of fields) {
        const Component = fieldComponentMap[field.type];
        current.push(
          <Component
            key={field.name}
            label={field.label}
            validationStatus={field.validationStatus}
            value={value[field.name]}
            onChange={internalOnChange(field.name)}
            disabled={field.disabled}
            required={field.required}
            multi={field.multi}
            options={field.options}
          />
        );
        if (current.length === max && current !== columns[1]) {
          current = columns[1];
        }
      }
      if (columns[columns.length - 1].length === 0) {
        columns.pop();
      }
      return columns;
    },
    [
      fields,
      value,
      internalOnChange,
    ],
  );

  return (
    <div className={styles.root}>
      <Form layout="vertical">
        <MetadataFormHeader
          title={title}
          validationStatus={validationStatus}
        />
        {
          fieldColumns[0].length > 0 ?
            <div style={{ height: 17 }}/>
            : null
        }
        <Row gutter={12} wrap={false}>
          {fieldColumns.map(column => (
            <Col flex="1">
              {column}
            </Col>
          ))}
        </Row>
      </Form>
    </div>
  );
};
