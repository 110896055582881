import { Button, Col, Row, Space } from 'antd';
import React, { useState } from 'react';
import { ContentZone } from '../../../views/content-zone/ContentZone';
import { MetadataFormProps } from '../../../views/metadata-form/MetadataForm';
import { FileState, FileStatus } from '../../../views/uploader/types';
import { UploaderProps } from '../../../views/uploader/Uploader';

function randNumber(min: number, max: number): number {
  let rand = min - 0.5 + Math.random() * (max - min + 1);
  return Math.round(rand);
}

export const ContentZoneSet: React.FC = () => {
  const [files, setFiles] = useState<FileState[]>([]);
  const [value, setValue] = useState<Record<string, any>>({});

  const onUpdateState = () => {
    const next = files.map(file => {
      const status = ([
        'idle',
        'uploading',
        'aborting',
        'success',
        'failed',
      ])[randNumber(0, 4)] as FileStatus;

      if (status === 'uploading') {
        return {
          ...file,
          status,
          uploadedSize: randNumber(0, file.file.size),
        };
      } else if (status === 'aborting') {
        return {
          ...file,
          status,
          uploadedSize: randNumber(0, file.file.size),
        };
      } else if (status === 'success') {
        return {
          ...file,
          status,
          uploadedSize: file.file.size,
        };
      } else if (status === 'failed') {
        return {
          ...file,
          status,
          uploadedSize: randNumber(0, file.file.size - 1),
          failedReason: 'Some upload error',
        };
      }

      return file;
    });

    setFiles(next);
  };

  const onRemove = (file: FileState) => {
    setFiles(files.filter(f => f !== file));
  };

  const onCancel = (file: FileState) => {
    setFiles(
      files.map(
        f => f === file ?
          ({
            ...f,
            status: 'idle',
            failedReason: undefined,
            uploadedSize: undefined,
          })
          : f,
      ),
    );
  };

  const onRetry = (file: FileState) => {
    setFiles(
      files.map(
        f => f === file ?
          ({
            ...f,
            status: 'uploading',
            failedReason: undefined,
            uploadedSize: randNumber(0, file.file.size - 1),
          })
          : f,
      ),
    );
  };

  const onClear = () => {
    setFiles([]);
  };

  const uploader: UploaderProps = {
    extensions: [
      'JPG',
      'PNG',
      'pdf',
      'key',
      'jpeg',
      'svg',
    ],
    allowFolders: true,
    maxFiles: 2,
    onRetry,
    onRemove,
    onCancel,
    onChange: n => setFiles(n),
    value: files,
    disabled: false,
    minImageSize: 100,
  };

  const metadataForm: MetadataFormProps = {
    fields: [
      {
        name: 'integer',
        label: 'Integer',
        validationStatus: '',
        disabled: false,
        type: 'integer',
      },
      {
        name: 'float',
        label: 'Float',
        validationStatus: '',
        disabled: false,
        type: 'float',
      },
      {
        name: 'text',
        label: 'Text',
        validationStatus: '',
        disabled: true,
        type: 'text',
      },
    ],
    onChange: next => setValue(next),
    title: 'Logos',
    value,
  };

  return (
    <Row gutter={24}>
      <Col span={18}>
        <ContentZone
          name="Logos"
          uploader={uploader}
          metadataForm={metadataForm}
        />
      </Col>
      <Col span={6}>
        <Space direction="vertical">
          <Button type="primary" onClick={onUpdateState}>Random state</Button>
          <Button type="primary" onClick={onClear}>Clear</Button>
        </Space>
      </Col>
    </Row>
  );
};
