import React from 'react';
import { ErrorIcon } from '../../../../../icons/ErrorIcon';
import { SuccessIcon } from '../../../../../icons/SuccessIcon';
import { FileState } from '../../../../types';
import { Body } from './elements/body/Body';
import { Preview } from './elements/preview/Preview';
import styles from './UploaderFile.module.css';

type UploaderFileProps = {
  file: FileState;
  onCancel(): void;
  onRemove(): void;
  onRetry(): void;
}

export const UploaderFile: React.FC<UploaderFileProps> = (props) => {
  const {
          file,
          onCancel,
          onRemove,
          onRetry,
        } = props;

  return (
    <div className={styles.root}>
      <div className={styles.previewContainer}>
        <Preview
          extension={file.ext}
          preview={file.previewUrl}
        />
      </div>
      <div className={styles.bodyContainer}>
        <Body
          file={file}
          onCancel={onCancel}
          onRemove={onRemove}
          onRetry={onRetry}
        />
      </div>
      <div className={styles.statusContainer}>
        {
          file.status === 'success' && (
            <SuccessIcon/>
          )
        }
        {
          file.status === 'failed' && (
            <ErrorIcon/>
          )
        }
      </div>
    </div>
  );
};
