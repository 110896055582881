import Title from 'antd/lib/typography/Title';
import React from 'react';
import { MetadataFormFieldValidationStatus } from '../../types';
import { ErrorIcon } from '../../../icons/ErrorIcon';
import { SuccessIcon } from '../../../icons/SuccessIcon';
import styles from './MetadataFormHeader.module.css';

export type MetadataFormHeaderProps = {
  title: string;
  validationStatus?: MetadataFormFieldValidationStatus;
}

export const MetadataFormHeader: React.FC<MetadataFormHeaderProps> = (props) => {
  return (
    <header className={styles.root}>
      <Title
        level={5}
        ellipsis
        style={{
          margin: 0
        }}
      >{props.title} Details</Title>
      {
        !!props.validationStatus && (
          <span className={styles.status}>
            {
              props.validationStatus === 'success' && (
                <SuccessIcon/>
              )
            }
            {
              props.validationStatus === 'error' && (
                <ErrorIcon/>
              )
            }
          </span>
        )
      }
    </header>
  );
}
