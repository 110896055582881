import Icon from '@ant-design/icons';
import React from 'react';

const Source: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="inherit"/>
    <path d="M7 12.0625L10.3333 16L17 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
  </svg>

);

export const SuccessIcon: React.FC = () => (
  <Icon
    component={Source}
    style={{
      fill: '#00CC4E',
      color: '#ffffff',
    }}
  />
);
