import React from 'react';

type ExtensionProps = {
  extension: string | null;
}

export const Extension: React.FC<ExtensionProps> = (props) => {
  return (
    <svg width="40" height="52" viewBox="0 0 40 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 6C0 2.68629 2.68629 0 6 0H27.2858C29.0111 0 30.6529 0.742695 31.7921 2.03847L35 5.6875L38.5062 9.67586C39.469 10.771 40 12.1792 40 13.6374V46C40 49.3137 37.3137 52 34 52H6C2.68629 52 0 49.3137 0 46V6Z" fill="#F2F3F5"/>
      {
        !!props.extension && (
          <text
            x="15%"
            y="60%"
            fill="#9599A1"
            textLength="70%"
            lengthAdjust="spacingAndGlyphs"
            fontSize={12}
            style={{
              fontWeight: 700
            }}
          >{props.extension!.toUpperCase()}</text>
        )
      }
    </svg>
  );
};
