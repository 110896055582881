import React, { ChangeEvent, useCallback } from 'react';
import { CustomFormItem } from '../../custom-form-item';
import { Input } from 'antd/';
import { MetadataFormFieldProps } from '../types';


export const FloatField: React.FC<MetadataFormFieldProps> = (props) => {
  const {
          label,
          disabled,
          value,
          validationStatus,
          onChange,
          required
        } = props;

  const internalOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;

      if (v) {
        const next = parseFloat(v);

        if (!isNaN(next)) {
          return onChange(next);
        }

        return onChange(null);
      }

      onChange(v || null);
    },
    [onChange]
  );

  return (
    <CustomFormItem
      label={label}
      validateStatus={validationStatus}
      required={required}
    >
      <Input
        type="number"
        step="0.1"
        disabled={disabled}
        value={value}
        onChange={internalOnChange}
        allowClear
      />
    </CustomFormItem>
  );
};
