import { Progress } from 'antd';
import niceBytes from 'nice-bytes';
import React, { useMemo } from 'react';
import { FileSimpleState } from '../../../../../../types';
import styles from './Body.module.css';

export type BodyProps = {
  file: FileSimpleState;
}

export const Body: React.FC<BodyProps> = (props) => {
  const {
          file,
        } = props;

  const progressPercent = useMemo<number>(
    () => {
      if (file.status === 'idle') {
        return 0;
      }

      if (!file.uploadedSize) {
        return 0;
      }

      return Number((file.uploadedSize / file.size * 100).toFixed(2));
    },
    [file],
  );

  const sizeStr = useMemo<string>(
    () => {
      return niceBytes(file.uploadedSize || 0).text;
    },
    [file],
  );

  return (
    <div className={styles.root}>
      <p className={styles.name}><span>{file.name}</span></p>
      <div className={styles.bar}>
        <span className={styles.status}>
          {
            file.status === 'failed' ?
              (file.failedReason || 'Failed')
              : file.status === 'starting' ?
                'Starting'
                : file.status === 'aborting' ?
                  'Canceling'
                  : progressPercent === 0
                    ? ''
                    :`${sizeStr} / ${progressPercent}%` + (file.status === 'pre-success' ? ' (Creating iconik asset)' : '')
          }
        </span>
      </div>
      <div className={styles.progress}>
        <Progress
          size="small"
          strokeColor={file.status === 'queued' || progressPercent === 0 ? 'grey' : undefined}
          status={
            file.status === 'uploading' || file.status === 'queued' ?
              'active'
              : file.status === 'success' ?
                'success'
                : file.status === 'failed' ?
                  'exception'
                  : 'normal'
          }
          percent={progressPercent === 0 ? 100 : progressPercent}
          showInfo={false}
        />
      </div>
    </div>
  );
};
