import axios, { AxiosError } from 'axios';
import { injectable } from 'inversify';
import { getOs } from '../utils/user-agent';

class WebDatError extends Error {}

// Source: url_status
export class SessionIdRequiredError extends WebDatError {
  constructor() {
    super('Session ID required');
  }
}

// Source: url_exec
export class InvalidExpedatUrlError extends WebDatError {
  constructor() {
    super('Query must be an expedat:// URL\turl_exec did not provide an expedat:// URL');
  }
}

export class NotAllowedError extends WebDatError {
  constructor() {
    super('Method Not Allowed\tOnly GET, OPTIONS, and HEAD methods are permitted.  Only the GET method performs actions');
  }
}

// Source: url_status
export class SessionIdNotFoundError extends WebDatError {
  constructor() {
    super('Session ID not found\tThe Session ID given to url_status is invalid or has timed out.');
  }
}

export interface ClientVersion {
  // WebDat
  name: string;
  // WebDat
  title: string;
  version: number;
  // Example: WebDat - 0.7.0 April 2023 - DEI, EXP-1.20.1, DOC-2.3.9 MTP-win64-4.4.3 4431
  MTPappstr: string;
  // Example: 00000000: ExpeDat-1.21Ab1 Pre-Release Trial - 230404, Expires 20230504
  MTPlicstr: string;
}

export enum State {
  // The action is waiting for user interaction, possibly from other actions.
  Waiting = 1,
  // The session is approved and network transactions have queued for execution.
  Queued = 2,
  // A network transaction related to this session is now running.
  Active = 3,
  // ExpeDat Desktop is waiting for user feedback.
  Prompting = 3,
  Retrying = 4,
  // No further actions are pending for this session. The session status will remain available for a few minutes, then it will be removed and the Session ID will become invalid.
  Done = 5, // for error and success??
}

export interface TransferTarget {
  // The base name of the file being transferred.
  target: string;
  // A non-zero value indicates that one or more errors have occurred. A value of 255 indicates that there were multiple results. See the results array for details.  Otherwise an MTP Error Class value indicates the source of the error. See [Tech Note 0013](https://www.dataexpedition.com/support/notes/tn0013.html) for MTP Error Class definitions.
  error_class: number;
  // 	The MTP Error Code for the given MTP Error Class.  See [Tech Note 0013](https://www.dataexpedition.com/support/notes/tn0013.html) for MTP Error Code definitions.
  error_code: number;
  // 	If error_class is non-zero, this will be a human readable description of the error.
  error_string: string;
  // The number of bytes successfully transferred so far.
  transferred: number;
  // The total size of this file, when known.  For some transactions, such as downloading a large S3 object, the total size may not be known until the transfer is complete.
  size: number;
  state_int: State;
  state_string: string;
}

export interface NewSession {
  session_id: string;
}

export interface SessionInfo {
  session_id: string;
  url: string;
  // A numerical indication of the progress of this session.
  state_int: State;
  // 	A human readable description of the session state.
  state_string: string;
  // A non-zero value indicates that one or more errors have occurred. A value of 255 indicates that there were multiple results. See the results array for details.  Otherwise an MTP Error Class value indicates the source of the error. See [Tech Note 0013](https://www.dataexpedition.com/support/notes/tn0013.html) for MTP Error Class definitions.
  error_class: number;
  // 	The MTP Error Code for the given MTP Error Class.  See [Tech Note 0013](https://www.dataexpedition.com/support/notes/tn0013.html) for MTP Error Code definitions.
  error_code: number;
  // 	If error_class is non-zero, this will be a human readable description of the error.
  error_string: string;
  // The unix time when this session began.
  start: number;
  // 	The total number of bytes transferred so far by this session.
  transferred: number;
  // The total number of bytes to transfer. Use this to compare with the transferred.
  expected: number;
  // The number of elements in the results array.
  targets: number;
  // The zero based index of the currently active element in the results array.  This value may be equal to targets when processing is complete.
  current: number;
  // An array of status information for each file target of the session.
  results: TransferTarget[];
  progress: {
    start: 0;
    eta: 0;
    speed: 0;
  }
}

export interface StartSessionOptions {
  user?: string;
  pass?: string;
  filter: string;
  maxFiles: number;
  remotePath?: string;
  handler?: string;
  encrypt?: boolean;
}

@injectable()
export class WebDatClient {
  static getInstallerUrl(): string {
      const os = getOs();
      switch (os) {
        case 'windows':
          return process.env.REACT_APP_WEB_DAT_INSTALLER_URL_WINDOWS!;
        case 'macos':
          return process.env.REACT_APP_WEB_DAT_INSTALLER_URL_MAC!;
        default:
          return process.env.REACT_APP_WEB_DAT_INSTALLER_URL_FALLBACK!;
      }
  }

  private httpClient = axios.create({
    baseURL: 'http://127.0.0.1:8091'
  });

  async id(): Promise<ClientVersion | undefined> {
    try {
      const response = await this.httpClient.get('/id', {
        timeout: 1000,
      });
      return response.data as ClientVersion;
    } catch (err) {
      return;
    }
  }

  async startUploadingSession(host: string, port?: number, options?: StartSessionOptions): Promise<NewSession> {
    const remotePath = options?.remotePath ?? '';
    const url = `expedat://${host}${port ? ':' + port : ''}${remotePath}`;
    const params = new URLSearchParams();
    const headers: Record<string, string> = {};
    params.append('a', 's');
    params.append('m', '1');
    if (options?.user) {
      params.append('u', options.user);
    }
    if (options?.pass) {
      params.append('p', options.pass);
    }
    if (options?.handler) {
      params.append('h', options.handler);
    }
    if (options?.encrypt !== undefined) {
      params.append('e', options.encrypt ? '1' : '0');
    }
    if (options?.maxFiles != null) {
      params.append('t', options.maxFiles + '');
    }
    if (options?.filter) {
      headers['X-ExpeDat-Filter'] = options.filter;
    }
    try {
      const response = await this.httpClient.get(`/url_exec?${url}?${params.toString()}`, {
        headers,
      });
      return response.data as SessionInfo
    } catch (err) {
      this.handleError('url_exec', err);
    }
  }

  async status(sessionId: string): Promise<SessionInfo> {
    try {
      const response = await this.httpClient.get('/url_status?' + sessionId);
      return response.data as SessionInfo;
    } catch (err) {
      this.handleError('url_status', err);
    }
  }

  private handleError(context: 'url_status' | 'url_exec', err: AxiosError): never {
    const httpStatus = err.response?.status;
    if (!httpStatus) {
      throw new WebDatError('WebDat client is not available');
    }
    if (httpStatus === 500) {
      throw new WebDatError('WebDat internal error');
    }
    if (httpStatus === 405) {
      throw new NotAllowedError();
    }
    if (context === 'url_exec') {
      if (httpStatus === 400) {
        throw new InvalidExpedatUrlError();
      }
    } else if (context === 'url_status') {
      if (httpStatus === 400) {
        throw new SessionIdRequiredError()
      } else if (httpStatus === 410) {
        throw new SessionIdNotFoundError();
      }
    }
    throw new WebDatError('WebDat: ' + err.response?.statusText);
  }
}
