import { Space } from 'antd';
import React, { PropsWithChildren, Children, useMemo, ReactNode } from 'react';
import { ReactComponent as Icon } from './elements/folder-icon.svg';
import styles from './UploaderFolder.module.css';

type UploaderFolderProps = PropsWithChildren<{
  title: string;
}>

export const UploaderFolder: React.FC<UploaderFolderProps> = (props) => {
  const count = useMemo(() => Children.count(props.children), [props.children]);

  const connectors = useMemo<ReactNode>(
    () => new Array(count).fill(0).map((_, index) => (
      <i key={index}/>
    )),
    [count]
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Icon className={styles.icon}/>
        <span>{props.title}</span>
      </div>
      <div className={styles.container}>
        <div className={styles.connectors}>
          <div>
            {connectors}
          </div>
        </div>
        <div className={styles.files}>
          <Space direction="vertical" size={24} style={{width: '100%'}}>
            {props.children}
          </Space>
        </div>
      </div>
    </div>
  );
};
