import React from 'react';
import styles from './Icon.module.css';

type IconProps = {}

export const Icon: React.FC<IconProps> = (props) => {
  return (
    <span className={styles.root}>{props.children}</span>
  );
};
