import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import { action, computed, observable, runInAction } from 'mobx';
import { ApiClient } from '../../../services/api-client/ApiClient';
import { RequestCancelError, RequestError } from '../../../services/api-client/errors';
import {
  ExpeDatConnectionSettings,
  PackageInfo,
  StartAssetUploadResult,
} from '../../../services/api-client/response-types';
import { FileUploader } from '../../../services/file-uploader/FileUploader';
import { getValidatorsMap } from './fieldsValidators';
import { UploadMethod, UploadStatus, ZoneAsset, ZoneAssetSimple, ZoneMetadata, ZoneS3, ZoneWebDat } from './types';
import { StartSessionOptions, State, TransferTarget, WebDatClient } from '../../../services/WebDat';
import { nanoid } from 'nanoid';
import { assertUploadMethod } from '../../utils';

// TODO: some content parameters was skipped from check
export class ContentZonesState {
  private S3_aborted: Map<string, boolean> = new Map();
  private WebDat_zoneAbortRequested: Set<string> = new Set();
  // Used to get uploader to abort process
  private S3_uploaders: Map<string, FileUploader> = new Map();
  // Used to avoid starting uploading twice
  private S3_starting: Map<string, Promise<StartAssetUploadResult>> = new Map();
  private WebDat_aborting: Map<string, {complete: () => void, process: Promise<void>}> = new Map();
  public destroyed = false;

  @observable zonesMap: {
    [name: string]: ZoneS3 | ZoneWebDat;
  } = {};
  @observable isAssetsAddingDisabledZone = new Set<string>();
  @observable isMetadataEditingDisabledZone = new Set<string>();

  @computed
  get totalAssets(): number {
    const zones = Object.values(this.zonesMap);
    let count = 0;
    for (const zone of zones) {
      count += zone.assets.length;
    }
    return count;
  }

  @computed
  get isAbleToStartUploading(): boolean {
    const zones = Object.values(this.zonesMap);
    let empty = true;
    for (const zone of zones) {
      const params = zone.contentParameters;
      const assets = zone.assets.length;
      if (assets > 0) {
        empty = false;
      }
      if (params.min) {
        if (assets < params.min) {
          return false;
        }
      }
      if (params.max) {
        if (assets > params.max) {
          return false;
        }
      }
    }
    return !empty;
  }

  @computed
  get isAllAssetsCompleted(): boolean {
    const zones = Object.values(this.zonesMap);
    for (const zone of zones) {
      if (zone.assets.some(a => a.status !== 'success')) {
        return false;
      }
    }
    return true;
  }

  constructor(
    private apiClient: ApiClient,
    private webDatClient: WebDatClient,
    private packageInfo: PackageInfo,
    private uploadMethod: UploadMethod,
    private previousState?: ContentZonesState,
  ) {
    this.loadZones(packageInfo, previousState);
  }

  validateMetadata(zoneName?: string) {
    if (zoneName) {
      return this.validateMetadataOfZone(zoneName);
    }
    const zones = Object.keys(this.zonesMap);
    for (const zone of zones) {
      this.validateMetadataOfZone(zone);
    }
  }

  @action.bound
  resetMetadataValidation(zoneName?: string) {
    if (zoneName) {
      const zone = this.zonesMap[zoneName];
      if (zone.metadata.validated) {
        zone.metadata.validated = false;
        zone.metadata.hasErrors = false;
        zone.metadata.errors = {};
      }
      return;
    }
    const zones = Object.values(this.zonesMap);
    for (const zone of zones) {
      if (zone.metadata.validated) {
        zone.metadata.validated = false;
        zone.metadata.hasErrors = false;
        zone.metadata.errors = {};
      }
    }
  }

  @action.bound
  resetZone(zoneName: string) {
    this.resetMetadataValidation(zoneName);
    const zone = this.zonesMap[zoneName];
    if (zone.uploadMethod === 'WebDat') {
      zone.status = 'idle';
      zone.progress = 0;
      zone.failedReason = '';
      zone.sessionId = undefined;
      zone.isSubmittingStated = false;
      zone.isAssetsLimitReached = false;
    }
    return;
  }

  @action.bound
  private validateMetadataOfZone(zoneName: string) {
    const zone = this.zonesMap[zoneName];

    if (!zone) {
      return;
    }

    const schema = zone.metadata.schema;
    const values = zone.metadata.values;
    const errors: ZoneMetadata['errors'] = {};
    const fields = Object.keys(schema);
    const validators = getValidatorsMap(zone.metadata.schema);

    zone.metadata.hasErrors = false;

    for (const field of fields) {
      const validate = validators.get(field);
      if (validate) {
        const result = validate(values[field]);
        if (!result.isValid) {
          if (!zone.metadata.hasErrors) {
            zone.metadata.hasErrors = true;
          }
          errors[field] = result.errorMessage || 'invalid';
        }
      }
    }

    zone.metadata.errors = errors;

    if (!zone.metadata.validated) {
      zone.metadata.validated = true;
    }
  }

  doesMetadataHasValidationError(zoneName?: string): boolean {
    if (zoneName) {
      return this.doesZoneMetadataHasValidationError(zoneName);
    }
    const zones = Object.keys(this.zonesMap);
    for (const zone of zones) {
      if (this.doesZoneMetadataHasValidationError(zone)) {
        return true;
      }
    }
    return false;
  }

  private doesZoneMetadataHasValidationError(zoneName: string): boolean {
    const zone = this.zonesMap[zoneName];

    if (!zone) {
      return false;
    }

    return zone.metadata.hasErrors;
  }

  isSubmittingCompleted(zoneName: string): boolean {
    const zone = this.zonesMap[zoneName];
    return zone.assets.some(a => a.status !== 'success');
  }

  isSubmittingStarted(zoneName: string): boolean {
    const zone = this.zonesMap[zoneName];
    return zone.isSubmittingStated;
  }

  @action.bound
  setMetadata(zoneName: string, values: Partial<ZoneMetadata['values']>) {
    if (this.isMetadataEditingDisabledZone.has(zoneName)) {
      return;
    }

    const zone = this.zonesMap[zoneName];

    if (!zone) {
      return;
    }

    zone.metadata.values = Object.assign(cloneDeep(zone.metadata.values), values);
  }

  @action.bound
  S3_setAbilityToAddAssets(value: boolean, zoneName?: string) {
    assertUploadMethod(this.uploadMethod, 'S3');
    if (zoneName) {
      if (value) {
        this.isAssetsAddingDisabledZone.delete(zoneName);
      } else {
        this.isAssetsAddingDisabledZone.add(zoneName);
      }
      return;
    }
    const zones = Object.keys(this.zonesMap);
    for (const zone of zones) {
      if (value) {
        this.isAssetsAddingDisabledZone.delete(zone);
      } else {
        this.isAssetsAddingDisabledZone.add(zone);
      }
    }
  }

  @action.bound
  setAbilityToEditMetadata(value: boolean, zoneName?: string) {
    if (zoneName) {
      if (value) {
        this.isMetadataEditingDisabledZone.delete(zoneName);
      } else {
        this.isMetadataEditingDisabledZone.add(zoneName);
      }
      return;
    }
    const zones = Object.keys(this.zonesMap);
    for (const zone of zones) {
      if (value) {
        this.isMetadataEditingDisabledZone.delete(zone);
      } else {
        this.isMetadataEditingDisabledZone.add(zone);
      }
    }
  }

  @action.bound
  S3_addAsset(zoneName: string, id: string, file: File, ext: string | null, previewUrl?: string) {
    assertUploadMethod(this.uploadMethod, 'S3');

    if (this.isAssetsAddingDisabledZone.has(zoneName)) {
      return;
    }

    const zone = this.zonesMap[zoneName] as ZoneS3;

    if (!zone) {
      return;
    }

    if (zone.isAssetsLimitReached) {
      return;
    }

    const asset: ZoneAsset = {
      id,
      file,
      previewUrl,
      uploadedSize: 0,
      status: 'idle',
      ext,
    };

    zone.assets = zone.assets.concat(asset);

    this.S3_updateIsAssetsLimitReachedFlag(zone);
  }

  @action.bound
  S3_removeAsset(zoneName: string, id: string) {
    assertUploadMethod(this.uploadMethod, 'S3');

    const zone = this.zonesMap[zoneName] as ZoneS3;

    if (!zone) {
      return;
    }

    zone.assets = zone.assets.filter(asset => asset.id !== id);

    this.S3_updateIsAssetsLimitReachedFlag(zone);
  }

  @action.bound
  private S3_updateIsAssetsLimitReachedFlag(zone: ZoneS3) {
    assertUploadMethod(this.uploadMethod, 'S3');
    if (zone.contentParameters.max !== undefined) {
      const next = zone.assets.length === zone.contentParameters.max;
      if (zone.isAssetsLimitReached !== next) {
        zone.isAssetsLimitReached = next;
      }
    }
  }

  async S3_abortUploadingAssets(isError: boolean, reason: string, zoneName?: string) {
    assertUploadMethod(this.uploadMethod, 'S3');
    const zonesNames = zoneName ? [zoneName] : Object.keys(this.zonesMap);
    const requests: Promise<void>[] = [];
    for (const zoneName of zonesNames) {
      const zone = this.zonesMap[zoneName];
      for (const asset of zone.assets) {
        if (asset.status !== 'success' && asset.status !== 'idle') {
          requests.push(this.abortAssetUploading(zoneName, asset.id, isError, reason, false, true));
        }
      }
    }
    await Promise.all(requests);
  }

  abortAssetUploading(zoneName: string, id: string, isError: boolean, reason: string, remove: boolean, returnPromise: true): Promise<void>;
  abortAssetUploading(zoneName: string, id: string, isError: boolean, reason: string, remove?: boolean, returnPromise?: false): void;
  abortAssetUploading(zoneName: string, id: string, isError: boolean, reason: string, remove?: boolean, returnPromise: boolean = false): Promise<void> | void {
    const zone = this.zonesMap[zoneName] as ZoneS3;

    if (!zone) {
      return;
    }

    const asset = zone.assets.find(a => a.id === id);

    if (!asset) {
      return;
    }

    if (asset.status !== 'uploading' && asset.status !== 'starting' && asset.status !== 'failed') {
      return;
    }

    if (asset.status === 'failed') {
      if (!asset.assetId && this.uploadMethod === 'S3') {
        if (remove) {
          this.S3_removeAsset(zoneName, id);
        }
      }
      return;
    }

    if (this.uploadMethod === 'S3') {
      this.S3_aborted.set(id, true);
    }

    this.patchAsset(zoneName, id, {
      status: 'aborting'
    });

    let promise: Promise<any> = asset.status === 'starting' && this.uploadMethod === 'S3' ?
      this.S3_starting.get(id)!.then(result => result.assetId, () => void 0)
      : Promise.resolve(asset.assetId as string);

    if (asset.status === 'uploading' && this.uploadMethod === 'S3') {
      const uploader = this.S3_uploaders.get(id);

      if (uploader) {
        uploader.cancel();
      }
    }

    promise = promise.then((assetId: string | undefined) => {
      if (!assetId) {
        return;
      }

      return this.apiClient.abortAssetUpload(assetId, isError, reason);
    });

    promise = promise.then(() => {
      if (remove && this.uploadMethod === 'S3') {
        this.S3_removeAsset(zoneName, id);
        return;
      }

      if (this.uploadMethod === 'S3') {
        this.patchAsset(zoneName, id, {
          status: 'failed',
          assetId: undefined,
          uploadParts: undefined,
          uploadedSize: 0,
          etags: undefined,
          failedReason: reason,
        } as Partial<ZoneAsset>);
      } else if (this.uploadMethod === 'WebDat') {
        this.patchAsset(zoneName, id, {
          status: 'failed',
          assetId: undefined,
          failedReason: reason,
        } as Partial<ZoneAssetSimple>);
      }
    });

    promise = promise.catch((error: RequestError) => {
      this.patchAsset(zoneName, id, {
        status: 'failed',
        failedReason: error.message,
      });
    });

    promise = promise.finally(() => {
      if (this.uploadMethod === 'S3') {
        this.S3_aborted.delete(id);
      }
    });

    if (returnPromise) {
      return promise;
    }
  }

  WebDat_completeAssetUploading(zoneName: string, id: string, returnPromise: true): Promise<void>;
  WebDat_completeAssetUploading(zoneName: string, id: string, returnPromise?: false): void;
  WebDat_completeAssetUploading(zoneName: string, id: string, returnPromise: boolean = false): Promise<void> | void {
    assertUploadMethod(this.uploadMethod, 'WebDat');

    const zone = this.zonesMap[zoneName] as ZoneWebDat;

    if (!zone) {
      return;
    }

    const asset = zone.assets.find(a => a.id === id);

    if (!asset) {
      return;
    }

    if (asset.status === 'success' || asset.status === 'failed') {
      return;
    }

    let promise: Promise<any> = Promise.resolve(asset.assetId!);

    promise = promise.then((assetId: string | undefined) => {
      if (!assetId) {
        return;
      }

      return this.apiClient.completeAssetUpload(assetId);
    });

    promise = promise.then(() => {
      this.patchAsset(zoneName, id, {
        status: 'success',
      } as Partial<ZoneAssetSimple>);
    });

    promise = promise.catch((error: RequestError) => {
      this.patchAsset(zoneName, id, {
        status: 'failed',
        failedReason: error.message,
      });
    });

    if (returnPromise) {
      return promise;
    }
  }

  S3_startAssetsUploading(zoneName?: string) {
    assertUploadMethod(this.uploadMethod, 'S3');
    const zonesNames = zoneName ? [zoneName] : Object.keys(this.zonesMap);
    for (const zoneName of zonesNames) {
      const zone = this.zonesMap[zoneName];
      if (zone.isSubmittingStated) {
        continue;
      }
      runInAction(() => {
        zone.isSubmittingStated = true;
      });
      for (const asset of zone.assets) {
        this.S3_internalStartAssetUpload(zoneName, asset.id)
          .catch(() => {
          });
      }
    }
  }

  S3_startAssetUploading(zoneName: string, id: string) {
    assertUploadMethod(this.uploadMethod, 'S3');
    if (this.doesZoneMetadataHasValidationError(zoneName)) {
      return;
    }
    this.S3_internalStartAssetUpload(zoneName, id)
      .catch(() => {
      });
  }

  @action.bound
  private loadZones(packageInfo: PackageInfo, previousState?: ContentZonesState) {
    this.zonesMap = {};
    for (const zoneName in packageInfo.contentParameters) {
      if (packageInfo.contentParameters.hasOwnProperty(zoneName)) {
        const contentParameters = cloneDeep(packageInfo.contentParameters[zoneName]);
        const metadata: Partial<ZoneMetadata> = {
          validated: false,
          errors: {},
          hasErrors: false,
        };
        const prevZoneState = previousState?.zonesMap[zoneName];
        if (prevZoneState) {
          Object.assign(metadata, cloneDeep(prevZoneState.metadata));
        } else {
          const assetsFields = packageInfo.assetsFields[zoneName] || [];
          metadata.schema = cloneDeep(pick(packageInfo.metadataSchema, assetsFields));
          metadata.values = assetsFields.reduce(
            (out, field) => {
              const fieldSchema = metadata.schema![field];
              let defaultValue = null;
              if (fieldSchema.type === 'tag_cloud') {
                defaultValue = [];
              } else if (fieldSchema.type === 'drop_down' && fieldSchema.multi) {
                defaultValue = [];
              } else if (fieldSchema.type === 'boolean') {
                defaultValue = false;
              }
              out[field] = defaultValue;
              return out;
            },
            {} as ZoneMetadata['values'],
          );
        }
        if (this.uploadMethod === 'S3') {
          this.zonesMap[zoneName] = {
            uploadMethod: 'S3',
            isSubmittingStated: false,
            isAssetsLimitReached: false,
            assets: [] as ZoneAsset[],
            contentParameters,
            metadata,
          } as ZoneS3;
        } else if (this.uploadMethod === 'WebDat') {
          this.zonesMap[zoneName] = {
            uploadMethod: 'WebDat',
            isSubmittingStated: false,
            status: 'idle',
            isAssetsLimitReached: false,
            assets: [] as ZoneAssetSimple[],
            contentParameters,
            progress: 0,
            metadata,
          } as ZoneWebDat;
        }

      }
    }
  }

  private async S3_internalStartAssetUpload(zoneName: string, id: string) {
    assertUploadMethod(this.uploadMethod, 'S3');
    const zone = this.zonesMap[zoneName] as ZoneS3;

    if (!zone) {
      return;
    }

    if (zone.metadata.hasErrors) {
      return;
    }

    const asset = (zone.assets as (ZoneAsset | ZoneAssetSimple)[]).find(a => a.id === id);

    if (!asset) {
      return;
    }

    if (['success', 'uploading', 'starting'].includes(asset.status)) {
      return;
    }

    if (this.S3_aborted.get(id)) {
      return;
    }

    try {
      if (asset.status === 'failed' && asset.assetId) {
        this.patchAsset(zoneName, id, {
          status: 'uploading',
          etags: [],
        });
      } else {
        this.patchAsset(zoneName, id, {
          status: 'starting',
        });

        const fileName = asset.file.name;
        const fileSize = asset.file.size;
        const startUploadPromise = this.apiClient.startAssetUpload({
          contentType: zoneName,
          fileName,
          fileSize,
          metadata: zone.metadata.values,
          uploadMethod: this.uploadMethod,
        });

        this.S3_starting.set(id, startUploadPromise);

        startUploadPromise
          .finally(() => {
            this.S3_starting.delete(id);
          });

        const result = await startUploadPromise;

        if (this.S3_aborted.get(id)) {
          return;
        }

        const updates = {
          status: 'uploading',
          assetId: result.assetId,
          uploadParts: result.parts,
        } as Partial<ZoneAsset | ZoneAssetSimple>;

        if (zone.uploadMethod === 'S3') {
          (updates as Partial<ZoneAsset>).uploadParts = result.parts;
        }

        this.patchAsset(zoneName, id, updates);
      }

      await this.S3_uploadAsset(zoneName, id);

      this.patchAsset(zoneName, id, {
        status: 'success',
      });
    } catch (error) {
      let reason = 'Error';

      if (error instanceof RequestError) {
        reason = error.message;
      } else if (error instanceof RequestCancelError || FileUploader.isCancelError(error)) {
        reason = 'Canceled';
      }

      if (this.S3_aborted.get(id)) {
        return;
      }

      this.patchAsset(zoneName, id, {
        status: 'failed',
        failedReason: reason,
      });
    }
  }

  private async S3_uploadAsset(zoneName: string, id: string) {
    assertUploadMethod(this.uploadMethod, 'S3');

    const zone = this.zonesMap[zoneName] as ZoneS3;

    if (!zone || zone.uploadMethod !== 'S3') {
      return;
    }

    const asset = zone.assets.find(a => a.id === id);

    if (!asset || !asset.uploadParts) {
      return;
    }

    const parts = asset.uploadParts!;
    const file = asset.file;
    const assetId = asset.assetId!;

    const uploader = new FileUploader(file, parts);

    this.S3_uploaders.set(asset.id, uploader);

    uploader.on('progress', (event: ProgressEvent) => {
      this.patchAsset(zoneName, id, {
        uploadedSize: event.loaded,
      });
    });

    try {
      const etags = await uploader.upload();

      this.S3_uploaders.delete(asset.id);

      await this.apiClient.completeAssetUpload(assetId, etags);

      this.patchAsset(zoneName, id, {
        etags,
      });
    } catch (error) {
      this.S3_uploaders.delete(asset.id);
      throw error;
    }
  }

  @action.bound
  private patchAsset(zoneName: string, id: string, updates: Partial<ZoneAsset | ZoneAssetSimple>) {
    const _zone = this.zonesMap[zoneName];

    if (!_zone) {
      return;
    }

    if (_zone.assets.some(a => a.id === id)) {
      _zone.assets = (_zone.assets as Record<string, any>[]).map(a => {
        if (a.id !== id) {
          return a;
        }

        return {
          ...a,
          ...updates,
        };
      }) as ZoneAsset[] | ZoneAssetSimple[];
    }
  };

  async WebDat_submit(zoneName: string): Promise<boolean> {
    assertUploadMethod(this.uploadMethod, 'WebDat');

    const zone = this.zonesMap[zoneName] as ZoneWebDat;

    // Submitting in canceling state
    if (this.WebDat_zoneAbortRequested.has(zoneName)) {
      return false;
    }

    if (zone.isSubmittingStated) {
      return false;
    }

    this.validateMetadata(zoneName);

    if (this.doesMetadataHasValidationError(zoneName)) {
      return false;
    }

    // If WebDat client is installed
    if (!(await this.webDatClient.id())) {
      return false;
    }

    this.setAbilityToEditMetadata(false, zoneName);

    runInAction(() => {
      zone.isSubmittingStated = true;
      zone.status = 'starting';
      zone.progress = 0;
      zone.failedReason = '';
      zone.assets = [];
    });

    let remotePath: string;

    // Initializing upload session
    try {
      const conn = await this.apiClient.getExpeDatConnectionSettings(this.packageInfo.iconikStorageId);
      remotePath = this.buildRemotePath(conn);
      const options: Partial<StartSessionOptions> = {
        user: conn.user,
        pass: conn.pass,
        remotePath,
        handler: conn.handler,
        encrypt: conn.encrypt,
      };
      if (zone.contentParameters.max) {
        options.maxFiles = zone.contentParameters.max;
      }
      const {extensions, excludeExtensions, allowExtensions} = zone.contentParameters;
      if (excludeExtensions && excludeExtensions.length !== 0) {
        options.filter = zone.contentParameters.excludeExtensions!.map(e => `!*.${e.toLowerCase()}`).join(';')
      } else if (allowExtensions && allowExtensions.length !== 0) {
        options.filter = zone.contentParameters.allowExtensions!.map(e => `*.${e.toLowerCase()}`).join(';')
      }  else if (extensions && extensions.length !== 0) {
        options.filter = zone.contentParameters.extensions!.map(e => `*.${e.toLowerCase()}`).join(';')
      }
      const session = await this.webDatClient.startUploadingSession(conn.host, conn.port, options as StartSessionOptions);
      runInAction(() => {
        zone.sessionId = session.session_id;
      });
    } catch (err) {
      runInAction(() => {
        zone.isSubmittingStated = false;
        zone.status = 'failed';
        zone.failedReason = err.message;
      });
      this.setAbilityToEditMetadata(true, zoneName);
      return false;
    }
    // List of files that were added to the watch list
    const handledFiles = new Map<string, ZoneAssetSimple>();
    let syncPromise: Promise<void[]> | undefined;
    while (true) {
      await new Promise((r) => setTimeout(r, 2500));
      // If there is a pending abort request then process it and exit
      if (this.WebDat_zoneAbortRequested.has(zoneName)) {
        runInAction(() => {
          zone.status = 'aborting';
        });
        for (const asset of zone.assets) {
          await this.abortAssetUploading(
            zoneName,
            asset.id,
            true,
            'Canceled',
            false,
            true
          );
          if (this.WebDat_aborting.has(asset.id)) {
            this.WebDat_aborting.get(asset.id)!.complete();
          }
        }
        runInAction(() => {
          zone.isSubmittingStated = false;
          zone.status = 'failed';
          zone.failedReason = 'Canceled';
        });
        this.WebDat_zoneAbortRequested.delete(zoneName);
        break;
      }

      const patchAssets = (targets: TransferTarget[], updateStatus = false) => {
        for (const target of targets) {
          const assetRec = handledFiles.get(target.target);
          if (!assetRec) {
            const asset = {
              assetId: undefined,
              id: nanoid(),
              status: this.getAssetStatusFromWebDatFile(target),
              file: {
                name: target.target.slice(target.target.indexOf('/') + 1),
                size: target.size
              },
              uploadedSize: target.transferred,
              failedReason: target.error_string ? target.error_string : undefined,
            } as ZoneAssetSimple;
            runInAction(() => {
              zone.assets = [...zone.assets, asset];
            });
            handledFiles.set(target.target, asset);
          } else if (updateStatus && (assetRec.status !== 'success' && assetRec.status !== 'failed' && assetRec.status !== 'pre-success')) {
            assetRec.status = this.getAssetStatusFromWebDatFile(target);
            this.patchAsset(zoneName, assetRec.id, {
              uploadedSize: target.transferred,
              status: assetRec.status,
              failedReason: target.error_string ? target.error_string : undefined,
            });
          }
        }
      }

      // Watching status
      try {
        const status = await this.webDatClient.status(zone.sessionId!);
        if (status.state_int === State.Waiting) {
          // Skip, user are choosing files/folder
        } else if (status.state_int === State.Queued) {
          if (zone.status !== 'queued') {
            runInAction(() => {
              zone.status = 'queued';
            });
          }
          patchAssets(status.results, false);
        } else if (status.state_int === State.Active || status.state_int === State.Retrying || status.state_int === State.Done) {
          const targets = status.results;
          runInAction(() => {
            if (status.expected !== 0) {
              zone.progress = Math.ceil(status.transferred / status.expected * 100);
            } else {
              zone.progress = 0;
            }
          });
          if (zone.status !== 'uploading' && status.state_int === State.Active) {
            if (status.state_int === State.Active) {
              runInAction(() => {
                zone.status = 'uploading';
              });
            } else if (status.state_int === State.Retrying) {
              runInAction(() => {
                zone.status = 'retrying';
              });
            }
          }
          patchAssets(status.results, true);
          syncPromise = ((syncPromise ? syncPromise : Promise.resolve()) as Promise<any>).then(() => Promise.all(
            targets.map(async target => {
              const success = target.size === target.transferred;
              const failed = !!target.error_string;
              const assetRec = handledFiles.get(target.target)!;
              if (!assetRec.assetId) {
                const idx = target.target.lastIndexOf('/');
                const filePath = idx === -1 ? '' : target.target.slice(0, idx);
                const fileName = idx === -1 ? target.target : target.target.slice(idx + 1);
                const fileSize = target.size;
                try {
                  const {assetId} = await this.apiClient.startAssetUpload({
                    contentType: zoneName,
                    fileName,
                    filePath,
                    fileSize,
                    metadata: zone.metadata.values,
                    uploadMethod: this.uploadMethod,
                  });
                  assetRec.assetId = assetId;
                  this.patchAsset(zoneName, assetRec.id, {
                    assetId,
                  });
                } catch (err) {
                  console.error(err);
                  return;
                }
              }
              if (failed) {
                this.abortAssetUploading(
                  zoneName,
                  assetRec.id,
                  true,
                  target.error_string,
                  false,
                  false
                );
              } else if (success) {
                try {
                  await this.WebDat_completeAssetUploading(
                    zoneName,
                    assetRec.id,
                    true
                  );
                } catch (err) {
                  this.patchAsset(zoneName, assetRec.id, {
                    status: 'failed',
                    failedReason: err.message,
                  });
                }
              }
            })
            // eslint-disable-next-line
          ));
          if (status.state_int === State.Done) {
            if (syncPromise) {
              await syncPromise;
            }
            patchAssets(status.results, true);
            if (status.error_class !== 0 || zone.assets.some(a => a.status === 'failed')) {
              runInAction(() => {
                zone.isSubmittingStated = false;
                zone.status = 'failed';
                zone.failedReason = status.error_string ?? 'Some files are uploaded successfully, but the associated assets were not created or updated';
              });
            } else {
              runInAction(() => {
                zone.isSubmittingStated = false;
                zone.status = 'success';
              });
            }
            break;
          }
        } else if (status.state_int === State.Prompting) {
          // Skip, Not used by WebDat
        }
      } catch (err) {
        runInAction(() => {
          zone.isSubmittingStated = false;
          zone.status = 'failed';
          zone.failedReason = err.message;
        });
        this.setAbilityToEditMetadata(true, zoneName);
        return false;
      }
    }
    this.setAbilityToEditMetadata(true, zoneName);
    return zone.status === 'success';
  }

  private buildRemotePath(conn: ExpeDatConnectionSettings): string {
    let remotePath = conn.remotePath ?? '';
    if (!remotePath.endsWith('/')) {
      remotePath += '/';
    }
    if (!remotePath.startsWith('/')) {
      remotePath = '/' + remotePath;
    }
    remotePath += this.packageInfo.id + '/';
    return remotePath;
  }

  async WebDat_abortAllActive() {
    assertUploadMethod(this.uploadMethod, 'WebDat');

    const zoneNames = Object.keys(this.zonesMap);

    for (const zoneName of zoneNames) {
      const zone = this.zonesMap[zoneName];
      if (!zone.isSubmittingStated) {
        continue;
      }
      if (this.WebDat_zoneAbortRequested.has(zoneName)) {
        continue
      }
      this.WebDat_zoneAbortRequested.add(zoneName);
      const assets = zone.assets;
      for (const asset of assets) {
        const id = asset.id;
        const record = {
          complete: () => {},
          process: new Promise<void>(r => {
            record.complete = () => r();
          }).then(() => {
            this.WebDat_aborting.delete(id)
          })
        };
        this.WebDat_aborting.set(id, record);
      }
    }
    const queue = Array.from(this.WebDat_aborting.values()).map(i => i.process);
    await Promise.all(queue);
  }

  private getAssetStatusFromWebDatFile(target: TransferTarget): UploadStatus {
    if (target.error_code !== 0) {
      return 'failed';
    } else if (target.transferred === 0) {
      return 'queued';
    } else if (target.transferred === target.size) {
      return 'pre-success';
    }
    return 'uploading';
  }
}
