import { Progress } from 'antd';
import React, { useMemo } from 'react';
import { FileState } from '../../../../../../types';
import niceBytes from 'nice-bytes';
import styles from './Body.module.css';

export type BodyProps = {
  file: FileState;
  onCancel(): void;
  onRemove(): void;
  onRetry(): void;
}

export const Body: React.FC<BodyProps> = (props) => {
  const {
        file,
        onRetry,
        onRemove,
        onCancel
        } = props;

  const progressPercent = useMemo<number>(
    () => {
      if (file.status === 'idle') {
        return 0;
      }

      if (!file.uploadedSize) {
        return 0;
      }

      return Number((file.uploadedSize / file.file.size * 100).toFixed(2));
    },
    [file]
  );

  const sizeStr = useMemo<string>(
    () => {
      return niceBytes(file.uploadedSize || 0).text;
    },
    [file]
  );

  const actions = useMemo(
    () => {
      if (file.status === 'aborting' || file.status === 'success') {
        return null;
      }

      return (
        <span className={styles.actions}>
          {
            file.status === 'failed' && (
              <button type="button" onClick={onRetry}>Try again</button>
            )
          }
          {
            file.status !== 'uploading' && file.status !== 'starting' && (
              <button type="button" onClick={onRemove}>Remove</button>
            )
          }
          {
            (file.status === 'uploading' || file.status === 'starting') && (
              <button type="button" onClick={onCancel}>Cancel</button>
            )
          }
        </span>
      )
    },
    [
      file,
      onCancel,
      onRemove,
      onRetry
    ]
  )

  return (
    <div className={styles.root}>
      <p className={styles.name}><span>{file.file.name}</span></p>
      <div className={styles.bar}>
        <span className={styles.status}>
          {
            file.status === 'failed' ?
              (file.failedReason || 'Failed')
              : file.status === 'starting' ?
                'Starting'
                : file.status === 'aborting' ?
                  'Canceling'
                  : `${sizeStr} / ${progressPercent}%`
          }
        </span>
        {actions}
      </div>
      <div className={styles.progress}>
        <Progress
          size="small"
          status={
            file.status === 'uploading' ?
              'active'
              : file.status === 'success' ?
                'success'
                : file.status === 'failed' ?
                  'exception'
                  : 'normal'
          }
          percent={progressPercent}
          showInfo={false}
        />
      </div>
    </div>
  );
};
