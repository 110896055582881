import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { ModalFuncProps } from 'antd/lib/modal/Modal';

// If handler uses the close fn then popup will be closed once the close called.
// If handler returns false|nothing, then popup will be closed.
// If handler returns true, then popup will be not closed.
// If handler return a Promise, then popup will be closed/not closed depending on promise result.
export type CloseHandler = ((close: () => {}) => void) | (() => boolean | void | Promise<any>);

export type SharedProps = {
  title?: string;
  onCancel?: CloseHandler;
  onOk?: CloseHandler;
}

const shared: ModalFuncProps = {
  width: 272,
  maskClosable: true,
  okButtonProps: {
    style: {
      minWidth: 112
    }
  }
};

export function mergeWithSharedConfig(config: ModalFuncProps): ModalFuncProps {
  return merge(cloneDeep(shared), config);
}
