import classNames from 'classnames';
import React, {useMemo } from 'react';
import { ReactComponent as Icon } from './elements/dropzone-icon.svg';
import styles from './UploaderDropzone.module.css';

interface ExtensionStr {
  extensions?: string;
  allowExtensions?: string;
  excludeExtensions?: string;
}

export type InfoPanelProps = {
  allowFolders?: boolean;
  maxFiles?: number;
  minFiles?: number;
  extensions?: string[];
  allowExtensions?: string[];
  excludeExtensions?: string[];
  minImageSize?: number;
  attachmentsEmail?: string;
  attachmentsName?: string;
}

export const InfoPanel: React.FC<InfoPanelProps> = (props) => {
  const {
    allowFolders = false,
    extensions,
    allowExtensions,
    excludeExtensions,
    minImageSize,
    maxFiles,
    minFiles,
    attachmentsEmail,
    attachmentsName
  } = props;

  const extStr: ExtensionStr = useMemo(
    () => ({
      extensions: extensions && extensions.map(e => e.toUpperCase()).join(', '),
      allowExtensions: allowExtensions && allowExtensions.map(e => e.toUpperCase()).join(', '),
      excludeExtensions: excludeExtensions && excludeExtensions.map(e => e.toUpperCase()).join(', '),
    }),
    [extensions, allowExtensions, excludeExtensions],
  );

  const minImageSizeStr = useMemo(
    () => {
      if (minImageSize == null || minImageSize <= 0) {
        return '';
      }

      return `Min size ${minImageSize}px on the longest side.`;
    },
    [minImageSize],
  );

  const minMaxFilesStr = useMemo(
    () => {
      if (!minFiles && maxFiles) {
        return 'Up to ' + maxFiles + ' file(s).';
      } else if (!maxFiles && minFiles) {
        return 'At least ' + minFiles + ' file(s).'
      } else if (maxFiles && minFiles) {
        if (maxFiles === minFiles) {
          return 'Required ' + maxFiles + ' file(s).';
        }
        return 'From ' + minFiles + ' to ' + maxFiles + ' file(s).'
      }
      return '';
    },
    [minFiles, maxFiles],
  );

  return (
    <div
      className={classNames(styles.root, {
        [styles.processing]: false,
      })}
      tabIndex={0}
    >
      <Icon/>
      <div style={{ height: 24 }}/>
      <p className={styles.caption}>
        <span>Click on the Submit button to upload your Files{allowFolders && ' or Folder'}</span>
      </p>
      <div style={{ height: 8 }}/>
      <p className={styles.subCaption}>
        Supports
        {' '}
        {
          extStr.excludeExtensions
            ? 'all file types except ' + extStr.excludeExtensions
            :  extStr.allowExtensions
              ? extStr.allowExtensions
              : extStr.extensions
                ? extStr.extensions
                : 'all file types'
        }
        .{minImageSizeStr && ` ${minImageSizeStr}`}{minMaxFilesStr && ` ${minMaxFilesStr}`}</p>
      {
        !!attachmentsEmail && !!attachmentsName && (
          <p
            className={styles.subCaption}
            style={{
              maxWidth: 255,
              textAlign: 'center',
              marginTop: 6
            }}
          >
            If your {attachmentsName.toLowerCase()} live on a webpage,
            please email the URL to <a href={`mailto:${attachmentsEmail}`}>{attachmentsEmail}</a>
            {' '}separately.
          </p>
        )
      }
    </div>
  );
};
