import { Layout } from 'antd';
import Title from 'antd/lib/typography/Title';
import { observer } from 'mobx-react';
import React from 'react';
import { GLOBAL_IOC_TYPES } from '../../ioc/iocTypes';
import { GlobalErrorState } from '../../states/GlobalErrorState';
import { useMobXState } from '../../states/utils';
import { LayoutFrame } from '../../views/layout/LayoutFrame';
import { Logo } from '../../views/logo/Logo';

type Props = {}

export const ErrorPage: React.FC<Props> = observer((props) => {
  const errorStage = useMobXState<GlobalErrorState>(GLOBAL_IOC_TYPES.states.GlobalError);

  if (errorStage.hasError) {
    return (
      <Layout style={{
        minHeight: '100vh'
      }}>
        <Layout.Header>
          <LayoutFrame>
            <Logo/>
          </LayoutFrame>
        </Layout.Header>
        <Layout.Content style={{
          minHeight: '100%',
          height: 1,
          paddingBottom: 88
        }}>
          <LayoutFrame style={{
            minHeight: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <div style={{
              textAlign: 'center'
            }}>
              <Title style={{
                fontSize: 80,
                fontWeight: 800,
                lineHeight: '98px',
                marginBottom: 24
              }}>{errorStage.errorTitle || 'Oops :-('}</Title>
              <p style={{
                maxWidth: 458,
                margin: '0 auto'
              }}>{errorStage.errorMessage!}</p>
            </div>
          </LayoutFrame>
        </Layout.Content>
      </Layout>
    );
  }

  return null;
});
