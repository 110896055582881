import { nanoid } from 'nanoid';
import { FileState, InternalFile } from '../../../types';
import { getFileExt } from './fileUtils';

const IMAGES_PREVIEW = [
  'png',
  'jpg',
  'svg',
  'jpeg'
];

export function generateFileInitialState(file: InternalFile): FileState {
  const ext = getFileExt(file);

  return {
    key: nanoid(),
    file,
    status: 'idle',
    ext: getFileExt(file),
    previewUrl: ext ?
      IMAGES_PREVIEW.includes(ext) ?
        URL.createObjectURL(file)
        : undefined
      : undefined
  };
}
