import { Space } from 'antd';
import React from 'react';
import { FileSimpleState } from '../../types';
import { UploaderFile } from './elements/file/UploaderFile';
import styles from './UploaderFileList.module.css';

type UploaderFileListProps = {
  files: FileSimpleState[];
}

export const UploaderFileList: React.FC<UploaderFileListProps> = (props) => {
  const {
          files,
        } = props;

  if (files.length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Space direction="vertical" size={24} style={{ width: '100%' }}>
        {
          files.length !== 0 && (
            <ul className={styles.list}>
              {
                files.map(file => (
                  <li key={file.key}>
                    <UploaderFile
                      file={file}
                    />
                  </li>
                ))
              }
            </ul>
          )
        }
      </Space>
    </div>
  );
};
