import { Button } from 'antd';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { GLOBAL_IOC_TYPES } from '../../ioc/iocTypes';
import { MetadataFieldType } from '../../services/api-client/response-types';
import { WebDatClient } from '../../services/WebDat';
import { GlobalErrorState } from '../../states/GlobalErrorState';
import { ContentZonesState } from '../../states/package/content-zones/ContentZonesState';
import { UploadMethod, Zone, ZoneS3, ZoneWebDat } from '../../states/package/content-zones/types';
import { PackageState } from '../../states/package/PackageState';
import { useMobXState } from '../../states/utils';
import { ContentZoneProps } from '../../views/content-zone/ContentZone';
import { MetadataFormProps } from '../../views/metadata-form/MetadataForm';
import { MetadataFormFieldType } from '../../views/metadata-form/types';
import { Popup } from '../../views/popup';
import { StepProps } from '../../views/steps/elements/Step';
import { FileSimpleState } from '../../views/uploader-submit/types';
import { UploaderProps } from '../../views/uploader/Uploader';
import { OnboardingPageView } from './view/OnboardingPageView';
import { UploaderSubmitProps } from '../../views/uploader-submit/UploaderSubmit';

type OnboardingPageProps = {}

type ZonesCache = {
  uploader: WeakMap<Zone, any>;
  metadataForm: WeakMap<Zone, any>;
}

export const OnboardingPage: React.FC<OnboardingPageProps> = observer(function OnboardingPage(props) {
  const packageState = useMobXState<PackageState>(GLOBAL_IOC_TYPES.states.Package);
  const errorState = useMobXState<GlobalErrorState>(GLOBAL_IOC_TYPES.states.GlobalError);
  const location = useLocation();

  const [steps, setSteps] = useState<StepProps[]>([]);
  const [contentZones, setContentZones] = useState<ContentZoneProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const refEventListeners = useRef<[(event: BeforeUnloadEvent) => any, (event: Event) => any] | undefined>();

  const tokenRef = useRef<string>();
  const initDefaultUploadMethod = useRef<boolean>();

  useEffect(
    () => {
      const query = new URLSearchParams(location.search);
      const token = query.get('token');

      if (tokenRef.current) {
        if (tokenRef.current === token) {
          return;
        }
      }

      if (!token) {
        errorState.set401Error();
        return;
      }

      tokenRef.current = token;
      packageState.load(token);
    },
    [
      location.search,
      errorState,
      packageState
    ],
  );

  useEffect(
    () => {
      return autorun(() => {
        if (!packageState.isPackageLoaded) {
          return;
        }

        const zonesNames = Object.keys(packageState.contentZones.zonesMap);

        if (zonesNames.length === 0) {
          return;
        }

        const nextSteps: StepProps[] = [];

        for (const zoneName of zonesNames) {
          const zone = packageState.contentZones.zonesMap[zoneName];
          const order = zone.contentParameters.order;

          const step: StepProps = {
            anchor: true,
            name: zoneName,
            status: !packageState.isSubmittingStarted ?
              'active'
              : zone.metadata.hasErrors ?
                'failed'
                : 'success',
          };

          nextSteps[order] = step;
        }

        setSteps(nextSteps);
      });
    },
    [
      packageState,
      setSteps,
    ],
  );

  useEffect(
    () => {
      return autorun(() => {
        if (packageState.isInProgress()) {
          if (!refEventListeners.current) {
            refEventListeners.current = [
              (e) => {
                return e.returnValue = 'Are you sure you want to change the upload method? Changing the method will result in the loss of any progress made. Please confirm your decision.';
              },
              (e) => {
                packageState.cancelSubmitting(false);
              },
            ];
            window.addEventListener('beforeunload', refEventListeners.current![0]);
            window.addEventListener('unload', refEventListeners.current![1]);
          }
        } else if (refEventListeners.current) {
          window.removeEventListener('beforeunload', refEventListeners.current![0]);
          window.removeEventListener('unload', refEventListeners.current![1]);
          refEventListeners.current = undefined;
        }
      });
    },
    [
      packageState
    ],
  );

  const zoneCacheRef = useRef<ZonesCache>();

  useEffect(
    () => {
      return autorun(
        () => {
          if (!packageState.isPackageLoaded) {
            if (zoneCacheRef.current) {
              zoneCacheRef.current = undefined;
            }
            return;
          }

          if (!zoneCacheRef.current) {
            zoneCacheRef.current = {
              uploader: new WeakMap(),
              metadataForm: new WeakMap(),
            };
          }

          const zonesNames = Object.keys(packageState.contentZones.zonesMap);

          if (zonesNames.length === 0) {
            return;
          }

          const nextContentZones: ContentZoneProps[] = new Array(zonesNames.length);

          for (const zoneName of zonesNames) {
            const order = packageState.contentZones.zonesMap[zoneName].contentParameters.order;
            const contentZone: ContentZoneProps = {
              name: zoneName,
              uploaderType: packageState.uploadMethod === 'S3' ? 'browser' : 'submit',
              uploader: packageState.uploadMethod === 'S3'
                ? getUploaderProps(zoneName, packageState.contentZones)
                : getUploaderSubmitProps(zoneName, packageState.contentZones),
              metadataForm: getMetadataFormProps(
                zoneName,
                packageState.contentZones,
                zoneCacheRef.current!.metadataForm,
              ),
            };

            nextContentZones[order] = contentZone;
          }

          setContentZones(nextContentZones);
        }
      );
    },
    [
      packageState,
      setContentZones,
    ],
  );

  useEffect(
    () => {
      return autorun(() => {
        if (packageState.isPackageLoaded) {
          if (packageState.uploadMethod === 'S3') {
            if (packageState.isSubmittingStarted) {
              if (packageState.isSubmittingCompleted) {
                const finish = () => packageState.S3_finishSubmitting();
                Popup.success({
                  title: 'Upload successful',
                  onOk: finish,
                  onCancel: finish,
                });
              }
            }
          }
        }
      });
    },
    [
      packageState
    ]
  );

  useEffect(
    () => {
      return autorun(() => {
        if (packageState.isPackageLoaded) {
          if (packageState.uploadMethod === 'S3') {
            if (packageState.isSubmittingStarted) {
              if (packageState.contentZones.totalAssets === 0) {
                packageState.cancelSubmitting(false);
              }
            }
          }
        }
      });
    },
    [
      packageState
    ]
  );

  const onChangeUploadingMethod = useCallback((value: UploadMethod, force?: boolean): boolean => {
    if (packageState.isInProgress() && !force) {
      return false;
    }
    Promise.resolve()
      .then(async () => {
        if (value === 'WebDat') {
          if (!(await packageState.isWebDatClientAvailable())) {
            return showInstallWebDatPopup(packageState);
          }
        }
        return true;
      })
      .then((change: boolean) => {
        if (change) {
          setIsLoading(true);
          return packageState.changeUploadMethod(value)
        }
      })
      .catch((err) => {
        console.error(err);
        Popup.error({
          title: 'Change upload method error',
          message: err.message
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    return true;
  }, [packageState, setIsLoading]);

  useEffect(
    () => {
      return autorun(() => {
        if (!packageState.isPackageLoaded) {
          initDefaultUploadMethod.current = false;
        }
        if (initDefaultUploadMethod.current) {
          return;
        }
        if (packageState.isPackageLoaded) {
          initDefaultUploadMethod.current = true;
          if (packageState.packageInfo.defaultUploadMethod === 'WebDat') {
            if (!packageState.isDefaultUploadMethodUsed) {
              onChangeUploadingMethod('WebDat');
            }
          }
        }
      });
    },
    [
      packageState,
      initDefaultUploadMethod,
      onChangeUploadingMethod
    ]
  );

  const disabledSubmit = useMemo(
    () =>
      !packageState.isPackageLoaded
      || !packageState.isAbleToStartSubmitting,
    [
      packageState.isPackageLoaded,
      packageState.isAbleToStartSubmitting,
    ],
  );

  return (
    <OnboardingPageView
      isLoading={packageState.isPackageLoading || !packageState.isPackageLoaded || isLoading}
      contentZones={contentZones}
      uploadMethod={packageState.uploadMethod}
      onChangeUploadMethod={onChangeUploadingMethod}
      steps={steps}
      disabledSubmit={disabledSubmit}
      onSubmit={packageState.uploadMethod === 'S3' ? packageState.S3_submit : undefined}
    />
  );
});

function showInstallWebDatPopup(packageState: PackageState): Promise<boolean> {
  return new Promise<boolean>(async (resolve) => {
    let stop = false;
    const installerUrl = WebDatClient.getInstallerUrl();
    const popupApi = Popup.block({
      message: (
        <div style={{
          padding: '40px 40px 20px',
        }}>
          <h2>Required steps</h2>
          <p>To enable Accelerated uploads, you need to install the WebDat Client.</p>
          <Button
            type="link"
            href={installerUrl}
            target="_blank"
          >Install WebDat</Button>
        </div>
      ),
      modal: {
        title: '',
        closable: true,
        cancelButtonProps: {
          style: {
            display: 'none',
          }
        },
        okButtonProps: {
          style: {
            display: 'none',
          }
        },
        icon: null,
        onCancel: () => {
          stop = true;
          popupApi.destroy();
          resolve(false);
        }
      },
    });
    while (!stop) {
      await new Promise(r => setTimeout(r, 5000));
      if (stop) {
        break;
      }
      if (await packageState.isWebDatClientAvailable()) {
        popupApi.destroy();
        resolve(true);
        break;
      }
    }
  });
}

function getUploaderSubmitProps(
  zoneName: string,
  contentZones: ContentZonesState
): UploaderSubmitProps {
  const zone = contentZones.zonesMap[zoneName] as ZoneWebDat;
  return {
    value: zone.assets.map(asset => ({
      key: asset.id,
      name: asset.file.name,
      failedReason: asset.failedReason,
      uploadedSize: asset.uploadedSize,
      status: asset.status,
      size: asset.file.size,
    }) as FileSimpleState),
    extensions: zone.contentParameters.extensions,
    allowExtensions: zone.contentParameters.allowExtensions,
    excludeExtensions: zone.contentParameters.excludeExtensions,
    minImageSize: zone.contentParameters.minImageSize,
    allowFolders: zone.contentParameters.allowFolders,
    maxFiles: zone.contentParameters.max,
    minFiles: zone.contentParameters.min,
    attachmentsEmail: zone.contentParameters.attachmentsEmail,
    attachmentsName: zoneName,
    showProgress: zone.status !== 'idle',
    progress: zone.progress,
    onSubmit: () =>  {
      contentZones.WebDat_submit(zoneName)
        .then((done) => {
          if (contentZones.destroyed) {
            return;
          }
          if (!done) {
            return;
          }
          const theRestZones = Object.keys(contentZones.zonesMap)
            .filter(z => {
              const zone = contentZones.zonesMap[z] as ZoneWebDat;
              return zone.status === 'idle' || zone.status === 'failed';
            });
          let message = `The ${zoneName} files have been successfully uploaded.`;
          if (theRestZones.length > 0) {
            message += ` Upload files for the remaining sections: ${theRestZones.join(', ')}`
          }
          Popup.success({
            title: 'Success',
            message,
          });
        })
    },
  };
}

function getUploaderProps(
  zoneName: string,
  contentZones: ContentZonesState
): UploaderProps {
  const zone = contentZones.zonesMap[zoneName] as ZoneS3;
  return {
    value: zone.assets.map(asset => ({
      key: asset.id,
      previewUrl: asset.previewUrl,
      ext: asset.ext,
      file: asset.file,
      status: asset.status,
      failedReason: asset.failedReason,
      uploadedSize: asset.uploadedSize,
    })),
    disabled:
      contentZones.isAssetsAddingDisabledZone.has(zoneName)
      || zone.isAssetsLimitReached,
    maxFileSize: zone.contentParameters.maxFileSize,
    extensions: zone.contentParameters.extensions,
    allowExtensions: zone.contentParameters.allowExtensions,
    excludeExtensions: zone.contentParameters.excludeExtensions,
    minImageSize: zone.contentParameters.minImageSize,
    allowFolders: zone.contentParameters.allowFolders,
    maxFiles: zone.contentParameters.max,
    minFiles: zone.contentParameters.min,
    attachmentsEmail: zone.contentParameters.attachmentsEmail,
    attachmentsName: zoneName,
    onChange: files => {
      const addList = files.filter(f => !zone.assets.find(zf => zf.id === f.key));
      for (const file of addList) {
        contentZones.S3_addAsset(zoneName, file.key, file.file, file.ext, file.previewUrl);
      }
    },
    onRetry: file => {
      contentZones.S3_startAssetUploading(zoneName, file.key);
    },
    onCancel: file => {
      contentZones.abortAssetUploading(zoneName, file.key, false, 'Canceled');
    },
    onRemove: file => {
      contentZones.S3_removeAsset(zoneName, file.key);
    },
  };
}

type ZoneMetadataFormCache = Pick<MetadataFormProps, 'onChange'>;

function getMetadataFormProps(
  zoneName: string,
  contentZones: ContentZonesState,
  cache: WeakMap<Zone, ZoneMetadataFormCache>,
): MetadataFormProps {
  const zone = contentZones.zonesMap[zoneName];

  if (!cache.get(zone)) {
    cache.set(zone, {
      onChange: value => {
        contentZones.setMetadata(zoneName, value);
      },
    });
  }

  const zoneCache = cache.get(zone)!;

  const schemaFieldsNames = Object.keys(zone.metadata.schema);

  return {
    title: zoneName,
    fields: schemaFieldsNames.map(fieldSchemaName => {
      const fieldSchema = zone.metadata.schema[fieldSchemaName];
      return ({
        type: getMetadataFormFieldType(fieldSchema.type),
        label: fieldSchema.label,
        name: fieldSchemaName,
        disabled: contentZones.isMetadataEditingDisabledZone.has(zoneName) || fieldSchema.readOnly,
        multi: fieldSchema.multi,
        options: fieldSchema.options,
        required: fieldSchema.required,
        validationStatus: !zone.metadata.validated ?
          ''
          : !!zone.metadata.errors[fieldSchemaName] ?
            'error'
            : 'success',
      });
    }),
    value: zone.metadata.values,
    ...zoneCache,
  };
}

function getMetadataFormFieldType(from: MetadataFieldType): MetadataFormFieldType {
  return from === 'string' ?
    'text'
    : from === 'boolean' ?
      'yesno'
      : from === 'string_exact' ?
        'text'
        : from === 'drop_down' ?
          'dropdown'
          : from === 'tag_cloud' ?
            'tagcloud'
            : from === 'text' ?
              'textarea'
              : from;
}
