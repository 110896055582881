import { Modal } from 'antd';
import classNames from 'classnames';
import { Icon } from '../../elements/icon/Icon';
import { mergeWithSharedConfig, SharedProps } from '../../shared';
import { ReactComponent as SVGIcon } from './elements/icon.svg';
import commonStyles from '../../styles.module.css';
import * as React from 'react';

export type ErrorPopupProps = {
  message?: string | React.ReactNode;
  title?: string;
} & SharedProps;

export function errorPopup(props?: ErrorPopupProps) {
  return Modal.success(mergeWithSharedConfig({
    title: props?.title ?? 'Error',
    content: props?.message ?? null,
    okButtonProps: {
      danger: true,
    },
    autoFocusButton: 'ok',
    className: classNames(commonStyles.root),
    icon: <Icon><SVGIcon/></Icon>,
    onCancel: props?.onCancel,
    onOk: props?.onOk,
  }));
}
