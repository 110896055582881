import React, { useCallback, useMemo } from 'react';
import { UploaderDropzone } from './elements/dropzone/UploaderDropzone';
import { UploaderFileList } from './elements/files-list/UploaderFileList';
import { FileState } from './types';

interface NormalizedExtensions {
  extensions?: string[];
  allowExtensions?: string[];
  excludeExtensions?: string[];
}

export type UploaderProps = {
  allowFolders?: boolean;
  maxFiles?: number;
  minFiles?: number;
  maxFileSize?: number;
  extensions?: string[];
  allowExtensions?: string[];
  excludeExtensions?: string[];
  minImageSize?: number;
  attachmentsEmail?: string;
  attachmentsName?: string;
  disabled?: boolean;
  value: FileState[];
  onChange(files: FileState[]): void;
  onCancel(file: FileState): void;
  onRemove(file: FileState): void;
  onRetry(file: FileState): void;
}

export const Uploader: React.FC<UploaderProps> = (props) => {
  const {
          allowFolders,
          minImageSize,
          extensions,
          allowExtensions,
          excludeExtensions,
          value,
          onChange,
          disabled,
          maxFiles,
          minFiles,
          maxFileSize,
          attachmentsEmail,
          attachmentsName,
          onRetry,
          onRemove,
          onCancel,
        } = props;

  const onFiles = useCallback(
    (files: FileState[]) => onChange(value.concat(files)),
    [
      value,
      onChange,
    ],
  );

  const normalizedExtensions: NormalizedExtensions = useMemo(
    () => ({
      extensions: extensions && extensions.map(e => e.toLowerCase()),
      allowExtensions: allowExtensions && allowExtensions.map(e => e.toLowerCase()),
      excludeExtensions: excludeExtensions && excludeExtensions.map(e => e.toLowerCase()),
    }),
    [extensions, allowExtensions, excludeExtensions],
  );

  return (
    <div>
      <UploaderDropzone
        extensions={normalizedExtensions.extensions}
        allowExtensions={normalizedExtensions.allowExtensions}
        excludeExtensions={normalizedExtensions.excludeExtensions}
        minImageSize={minImageSize}
        allowFolders={allowFolders}
        attachmentsEmail={attachmentsEmail}
        attachmentsName={attachmentsName}
        maxFiles={maxFiles}
        minFiles={minFiles}
        maxFileSize={maxFileSize}
        disabled={disabled}
        onFiles={onFiles}
      />
      <UploaderFileList
        files={value}
        onCancel={onCancel}
        onRemove={onRemove}
        onRetry={onRetry}
      />
    </div>
  );
};
