import { Button, Card, Layout, Space, Spin, Switch } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback } from 'react';
import { ContentZone, ContentZoneProps } from '../../../views/content-zone/ContentZone';
import { LayoutFrame } from '../../../views/layout/LayoutFrame';
import { Logo } from '../../../views/logo/Logo';
import { StepProps } from '../../../views/steps/elements/Step';
import { Steps } from '../../../views/steps/Steps';
import styles from './OnboardingPageView.module.css';

export type OnboardingPageViewProps = {
  contentZones: ContentZoneProps[];
  steps: StepProps[];
  disabledSubmit?: boolean;
  isLoading: boolean;
  uploadMethod: 'S3' | 'WebDat';
  // If false is returned then user force = true to change the upload method
  onChangeUploadMethod(value: 'S3' | 'WebDat', force?: boolean): boolean;
  onSubmit?: (() => void) | undefined;
}

export const OnboardingPageView: React.FC<OnboardingPageViewProps> = (props) => {
  const {
    contentZones,
    disabledSubmit,
    uploadMethod,
    onChangeUploadMethod,
    onSubmit,
    isLoading,
    steps,
  } = props;

  const onChangeUploadMethodInternal = useCallback(() => {
    const next = uploadMethod === 'S3' ? 'WebDat' : 'S3';
    const needConfirm = !onChangeUploadMethod(next);
    if (needConfirm) {
      if (window.confirm('Are you sure you want to change the upload method? Changing the method will result in the loss of any progress made. Please confirm your decision.')) {
        onChangeUploadMethod(next, true);
      }
    }
  }, [uploadMethod, onChangeUploadMethod]);

  if (isLoading) {
    return (
      <div style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Spin />
      </div>
    );
  }
  

  return (
    <>
      <Layout>
        <Layout.Header>
          <LayoutFrame>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Logo />
              <div>
                <span style={{ marginRight: 12 }}>Use Acceleration</span>
                <Switch
                  checkedChildren="WebDat"
                  unCheckedChildren="S3"
                  checked={uploadMethod === 'WebDat'}
                  onChange={onChangeUploadMethodInternal}
                />
              </div>
            </div>
          </LayoutFrame>
        </Layout.Header>
        <Layout.Content style={{position: 'relative'}}>
          <LayoutFrame>
            <Title
              level={1}
            >Media Asset Submission</Title>
            <div style={{ height: 48 }} />
            <Space
              size={80}
              direction="vertical"
              style={{ width: '100%' }}
            >
              {
                contentZones.map(contentZone => (
                  <ContentZone
                    key={contentZone.name}
                    {...contentZone}
                  />
                ))
              }
            </Space>
            <div className={styles.steps}>
              <Card className={styles.stepsCard} bordered={false}>
                <Steps
                  anchor
                  steps={steps}
                />
              </Card>
            </div>
          </LayoutFrame>
        </Layout.Content>
        {
          uploadMethod === 'S3' && (
            <Layout.Footer>
              <LayoutFrame>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div>

                  </div>
                  <div style={{
                    marginLeft: 'auto',
                  }}>
                    <Button
                      type="primary"
                      onClick={onSubmit}
                      disabled={disabledSubmit}
                    >Submit</Button>
                  </div>
                </div>
              </LayoutFrame>
            </Layout.Footer>
          )
        }
      </Layout>

    </>
  );
};
