import { Button, Col, Collapse, Form, Input, Row, Space } from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { GLOBAL_IOC_TYPES } from '../../../ioc/iocTypes';
import { PackageState } from '../../../states/package/PackageState';
import { useMobXState } from '../../../states/utils';
import { ContentZone } from '../../../views/content-zone/ContentZone';
import { CustomFormItem } from '../../../views/custom-form-item';
import { FileState } from '../../../views/uploader/types';
import { ZoneS3 } from '../../../states/package/content-zones/types';

type PackageStateSetProps = {}

export const PackageStateSet: React.FC<PackageStateSetProps> = observer(() => {
  const packageState = useMobXState<PackageState>(GLOBAL_IOC_TYPES.states.Package);
  const [tokenInput, setTokenInput] = useState<string>('')

  const onLoadToken = () => {
    setTokenInput('');
    packageState.load(tokenInput);
  }

  const contentZones = useMemo<string[]>(() => {
    if (!packageState.isPackageLoaded) {
      return []
    }
    return Object.keys(packageState.contentZones.zonesMap);
  }, [
    packageState.contentZones,
    packageState.isPackageLoaded
  ]);

  return (
    <div>
      <Row gutter={48}>
        <Col span={12}>
          <Form layout="vertical">
            <CustomFormItem
              label="Package token"
            >
              <Input
                value={tokenInput}
                onChange={e => setTokenInput(e.target.value)}
                allowClear
              />
            </CustomFormItem>
            <CustomFormItem>
              <Space direction="horizontal">
                <Button
                  disabled={!tokenInput}
                  onClick={onLoadToken}
                >Load package</Button>
                <Button
                  type="primary"
                  disabled={!packageState.isPackageLoaded || !packageState.isAbleToStartSubmitting}
                  onClick={() => packageState.S3_submit()}
                >Submit</Button>
                <Button
                  type="primary"
                  disabled={!packageState.isPackageLoaded || !packageState.isSubmittingStarted}
                  onClick={() => packageState.cancelSubmitting()}
                >Abort session</Button>
                <Button
                  type="primary"
                  disabled={!packageState.isPackageLoaded || !packageState.isSubmittingCompleted}
                  onClick={() => packageState.S3_finishSubmitting()}
                >Complete session</Button>
              </Space>
            </CustomFormItem>
          </Form>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{width: '100%'}}>
            <Collapse>
              <Collapse.Panel
                header="PackageState summary"
                key="1"
              >
              <pre>{JSON.stringify({
                isPackageLoading: packageState.isPackageLoading,
                isPackageLoaded: packageState.isPackageLoaded,
                isSubmittingStarted: packageState.isSubmittingStarted,
                isSubmittingCompleted: packageState.isSubmittingCompleted,
                isAbleToStartSubmitting: packageState.isAbleToStartSubmitting,
                contentZones: packageState.contentZones && {
                  isAssetsAddingDisabledZone: Array.from(packageState.contentZones.isAssetsAddingDisabledZone.values()),
                  isAllAssetsCompleted: packageState.contentZones.isAllAssetsCompleted
                }
              }, null, 2)}</pre>
              </Collapse.Panel>
            </Collapse>
            <Collapse>
              <Collapse.Panel
                header="PackageState"
                key="1"
              >
                <pre>{JSON.stringify(packageState, null, 2)}</pre>
              </Collapse.Panel>
            </Collapse>
          </Space>
        </Col>
      </Row>
      <div style={{height: 48}}/>
      <Space direction="vertical" style={{width: '100%'}} size={48}>
        {
          contentZones.length !== 0 && (
            contentZones.map(zoneName => {
              const zone = packageState.contentZones.zonesMap[zoneName] as ZoneS3;
              return (
                <ContentZone
                  key={zoneName}
                  name={zoneName}
                  uploader={{
                    minFiles: zone.contentParameters.min,
                    disabled: packageState.contentZones.isAssetsAddingDisabledZone.has(zoneName) || zone.isAssetsLimitReached,
                    minImageSize: zone.contentParameters.minImageSize,
                    extensions: zone.contentParameters.extensions,
                    maxFiles: zone.contentParameters.max,
                    allowFolders: zone.contentParameters.allowFolders,
                    maxFileSize: zone.contentParameters.maxFileSize,
                    value: zone.assets.map(a => ({
                      uploadedSize: a.uploadedSize,
                      failedReason: a.failedReason,
                      status: a.status,
                      file: a.file,
                      previewUrl: a.previewUrl,
                      ext: a.ext,
                      key: a.id
                    })),
                    onRetry(file: FileState) {
                      packageState.contentZones.S3_startAssetUploading(zoneName, file.key);
                    },
                    onRemove(file: FileState) {
                      packageState.contentZones.S3_removeAsset(zoneName, file.key);
                    },
                    onCancel(file: FileState) {
                      packageState.contentZones.abortAssetUploading(zoneName, file.key, false, 'Canceled');
                    },
                    onChange(files: FileState[]) {
                      console.log('[%s.uploader] onChange', zoneName, files);
                      const addList = files.filter(f => !zone.assets.find(zf => zf.id === f.key));
                      for (const file of addList) {
                        packageState.contentZones.S3_addAsset(zoneName, file.key, file.file, file.ext, file.previewUrl);
                      }
                    },
                  }}
                  metadataForm={{
                    fields: Object.keys(zone.metadata.schema).map(f => {
                      const hasErrors = !!zone.metadata.errors[f];
                      const schema = zone.metadata.schema[f];
                      return {
                        validationStatus: hasErrors ? 'error' : '',
                        options: schema.options,
                        multi: schema.multi,
                        disabled: false,
                        name: f,
                        label: schema.label,
                        type: schema.type === 'string' ?
                          'text'
                          : schema.type === 'boolean' ?
                            'yesno'
                            : schema.type === 'string_exact' ?
                              'text'
                              : schema.type === 'drop_down' ?
                                'dropdown'
                                : schema.type === 'tag_cloud' ?
                                  'tagcloud'
                                  : schema.type,
                      }
                    }),
                    value: zone.metadata.values,
                    title: zoneName,
                    onChange(value, changed) {
                      packageState.contentZones.setMetadata(zoneName, value);
                    },
                  }}
                />
              );
            })
          )
        }
      </Space>
    </div>
  );
});
