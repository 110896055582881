import { Card, Layout, Tabs } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { ButtonsSet } from './sets/ButtonsSet';
import { ContentZoneSet } from './sets/ContentZoneSet';
import { FormsSet } from './sets/FormsSet';
import { MetadataFormSet } from './sets/MetadataFormSet';
import { PackageStateSet } from './sets/PackageStateSet';
import { PopupSet } from './sets/PopupSet';
import { UploaderSet } from './sets/UploaderSet';
import { ContentZoneSubmitSet } from './sets/ContentZoneSubmitSet';
import { UploaderSubmitSet } from './sets/UploaderSubmitSet';

const { TabPane } = Tabs;

// Play with app component/state/services in development mode
export const SandboxPage: React.FC<{}> = () => {
  return (
    <Layout
      style={{
        background: 'inherit',
      }}
    >
      <Header>
        <Title
          style={{
            color: '#ffffff',
            textAlign: 'center',
          }}
        >Sandbox</Title>
      </Header>
      <Content style={{
        padding: '20px 48px',
      }}>
        <Tabs
          defaultActiveKey="buttons"

        >
          <TabPane tab="Buttons" key="buttons">
            <Card>
              <ButtonsSet/>
            </Card>
          </TabPane>
          <TabPane tab="Forms" key="forms">
            <Card>
              <FormsSet/>
            </Card>
          </TabPane>
          <TabPane tab="Font" key="font">
            <Card>
              <Title level={1}>Title1</Title>
              <Title level={2}>Title2</Title>
              <Title level={3}>Title3</Title>
              <Title level={4}>Title4</Title>
              <Title level={5}>Title5</Title>
              <p>Paragraph</p>
            </Card>
          </TabPane>
          <TabPane tab="Metadata Form" key="metadataforms">
            <MetadataFormSet/>
          </TabPane>
          <TabPane tab="Uploader" key="uploader">
            <UploaderSet/>
          </TabPane>
          <TabPane tab="Uploader (Submit)" key="uploaderSubmit">
            <UploaderSubmitSet/>
          </TabPane>
          <TabPane tab="Content Zone" key="contentZone">
            <ContentZoneSet/>
          </TabPane>
          <TabPane tab="Content Zone (Submit)" key="contentZoneSubmit">
            <ContentZoneSubmitSet/>
          </TabPane>
          <TabPane tab="Package state" key="packageState">
            <PackageStateSet/>
          </TabPane>
          <TabPane tab="Popups" key="popups">
            <PopupSet/>
          </TabPane>
        </Tabs>
      </Content>
    </Layout>
  );
};
