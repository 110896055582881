// IoC Container builder

import { createBrowserHistory, History } from 'history';
import { Container } from 'inversify';
import { ApiClientFactory } from '../services/api-client/ApiClientFactory';
import { GlobalErrorState } from '../states/GlobalErrorState';
import { PackageState } from '../states/package/PackageState';
import { GLOBAL_IOC_TYPES } from './iocTypes';
import { WebDatClient } from '../services/WebDat';

// Bind app services to container
export function buildContainer(target: Container) {
  target.bind<History>(GLOBAL_IOC_TYPES.browserHistory).toConstantValue(createBrowserHistory());
  target.bind<GlobalErrorState>(GLOBAL_IOC_TYPES.states.GlobalError).to(GlobalErrorState);
  target.bind<ApiClientFactory>(GLOBAL_IOC_TYPES.services.ApiClientFactory).to(ApiClientFactory);
  target.bind<WebDatClient>(GLOBAL_IOC_TYPES.services.WebDatClient).to(WebDatClient);
  target.bind<PackageState>(GLOBAL_IOC_TYPES.states.Package).to(PackageState);

  return target;
}

