import classNames from 'classnames';
import React, { ChangeEvent, DragEvent, MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import { Popup } from '../../../popup';
import { FileState, InternalFile } from '../../types';
import { ReactComponent as Icon } from './elements/dropzone-icon.svg';
import styles from './UploaderDropzone.module.css';
import { prepareFileStates } from './utils/prepareFileStates';
import { traverseFileTree } from './utils/traversFilesTree';

interface ExtensionStr {
  extensions?: string;
  allowExtensions?: string;
  excludeExtensions?: string;
}

export type UploaderDropzoneProps = {
  allowFolders?: boolean;
  maxFiles?: number;
  minFiles?: number;
  maxFileSize?: number;
  extensions?: string[];
  allowExtensions?: string[];
  excludeExtensions?: string[];
  minImageSize?: number;
  attachmentsEmail?: string;
  attachmentsName?: string;
  disabled?: boolean;
  onFiles(files: FileState[]): void;
}

export const UploaderDropzone: React.FC<UploaderDropzoneProps> = (props) => {
  const {
          allowFolders = false,
          extensions,
          allowExtensions,
          excludeExtensions,
          minImageSize,
          maxFileSize,
          maxFiles,
          minFiles,
          onFiles,
          attachmentsEmail,
          attachmentsName,
          disabled     = false,
        } = props;

  const [dragOver, setDragOver] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const extStr: ExtensionStr = useMemo(
    () => ({
      extensions: extensions && extensions.map(e => e.toUpperCase()).join(', '),
      allowExtensions: allowExtensions && allowExtensions.map(e => e.toUpperCase()).join(', '),
      excludeExtensions: excludeExtensions && excludeExtensions.map(e => e.toUpperCase()).join(', '),
    }),
    [extensions, allowExtensions, excludeExtensions],
  );

  const minImageSizeStr = useMemo(
    () => {
      if (minImageSize == null || minImageSize <= 0) {
        return '';
      }

      return `Min size ${minImageSize}px on the longest side.`;
    },
    [minImageSize],
  );

  const minMaxFilesStr = useMemo(
    () => {
      if (!minFiles && maxFiles) {
        return 'Up to ' + maxFiles + ' file(s).';
      } else if (!maxFiles && minFiles) {
        return 'At least ' + minFiles + ' file(s).'
      } else if (maxFiles && minFiles) {
        if (maxFiles === minFiles) {
          return 'Required ' + maxFiles + ' file(s).';
        }
        return 'From ' + minFiles + ' to ' + maxFiles + ' file(s).'
      }
      return '';
    },
    [minFiles, maxFiles],
  );

  const multiple = useMemo(
    () => {
      if (typeof maxFiles === 'number') {
        return maxFiles > 1;
      }

      return true;
    },
    [maxFiles],
  );

  const inputAccept = useMemo<string | undefined>(
    () => {
      return extensions && extensions.map(e => `.${e.toLowerCase()}`).join(',');
    },
    [extensions]
  );

  const onRootClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const onInputClick = useCallback(
    (e: MouseEvent<HTMLInputElement>) =>
      e.stopPropagation(),
    [],
  );

  const onInputChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      const files = e.target.files;

      if (!files || files.length === 0) {
        return;
      }

      setProcessing(true);

      try {
        const result = await prepareFileStates(Array.from(files), {
          maxFileSize,
          extensions,
          allowExtensions,
          excludeExtensions,
          minImageSize,
          maxFiles,
        });

        if (files.length === 1) {
          if (result.length === 0) {
            Popup.error({
              title: 'Incorrect file type'
            });
          }
        }

        if (result.length > 0) {
          onFiles(result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
        setProcessing(false);
      }
    },
    [
      onFiles,
      setProcessing,
      maxFiles,
      minImageSize,
      extensions,
      allowExtensions,
      excludeExtensions,
      maxFileSize,
    ]);

  // Reference: https://github.com/react-component/upload/blob/669223730a13762f6e3ad6dc9334e80dd1ee8200/src/AjaxUploader.tsx#L63
  const onRootDrop = useCallback(async (e: DragEvent) => {
    e.preventDefault();

    if (e.type === 'dragover') {
      setDragOver(true);
      return;
    }

    setDragOver(false);

    if (e.type === 'dragleave') {
      return;
    }

    setProcessing(true);

    let files: InternalFile[] = [];

    if (allowFolders) {
      if (e.dataTransfer.items.length > 0) {
        const result = await traverseFileTree(
          Array.prototype.slice.call(e.dataTransfer.items),
          () => true,
        );

        files.push(...result);
      }
    } else {
      if (e.dataTransfer.files.length > 0) {
        files.push(...Array.prototype.slice.call(e.dataTransfer.files));
      }
    }

    try {
      const result = await prepareFileStates(files, {
        maxFileSize,
        extensions,
        allowExtensions,
        excludeExtensions,
        minImageSize,
        maxFiles,
      });

      if (files.length === 1) {
        if (result.length === 0) {
          Popup.error({
            title: 'Incorrect file type'
          });
        }
      }

      if (result.length > 0) {
        onFiles(result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
    }
  }, [
    onFiles,
    setDragOver,
    setProcessing,
    maxFiles,
    allowFolders,
    minImageSize,
    extensions,
    allowExtensions,
    excludeExtensions,
    maxFileSize,
  ]);

  const events = useMemo(() => {
    if (disabled) {
      return;
    }

    return {
      onClick: onRootClick,
      onDrop: onRootDrop,
      onDragOver: onRootDrop,
      onDragLeave: onRootDrop,
    };
  }, [
    disabled,
    onRootClick,
    onRootDrop,
  ]);

  return (
    <div
      className={classNames(styles.root, {
        [styles.dragOver]: dragOver,
        [styles.disabled]: disabled,
        [styles.processing]: processing,
      })}
      role="button"
      tabIndex={0}
      {...events}
    >
      {/*TODO: sometimes some elements of the icon is disappeared*/}
      <Icon/>
      <div style={{ height: 24 }}/>
      <p className={styles.caption}>
        <span>Drop your Files{allowFolders && ' or Folder'} here, or </span><span
        className={styles.captionLink}>browse</span>
      </p>
      <div style={{ height: 8 }}/>
      <p className={styles.subCaption}>
        Supports
        {' '}
        {
          extStr.excludeExtensions
            ? 'all file types except ' + extStr.excludeExtensions
            :  extStr.allowExtensions
              ? extStr.allowExtensions
              : extStr.extensions
                ? extStr.extensions
                : 'all file types'
        }
        .{minImageSizeStr && ` ${minImageSizeStr}`}{minMaxFilesStr && ` ${minMaxFilesStr}`}</p>
      {
        !!attachmentsEmail && !!attachmentsName && (
          <p
            className={styles.subCaption}
            style={{
              maxWidth: 255,
              textAlign: 'center',
              marginTop: 6
            }}
          >
            If your {attachmentsName.toLowerCase()} live on a webpage,
            please email the URL to <a href={`mailto:${attachmentsEmail}`}>{attachmentsEmail}</a>
            {' '}separately.
          </p>
        )
      }
      <input
        ref={inputRef}
        type="file"
        accept={inputAccept}
        onClick={onInputClick}
        onChange={onInputChange}
        style={{ display: 'none' }}
        multiple={multiple}
      />
    </div>
  );
};
