import { Anchor } from 'antd';
import React, { useMemo } from 'react';
import { ErrorIcon } from '../../icons/ErrorIcon';
import { SuccessIcon } from '../../icons/SuccessIcon';
import _slug from 'slug';

export type StepProps = {
  name: string;
  anchor?: boolean;
  status:
    | 'active'
    | 'success'
    | 'failed'
}

export const Step: React.FC<StepProps> = (props) => {
  const {
          name,
          status,
          anchor
        } = props;

  const slug: string = useMemo(() => _slug(name), [name]);

  return (
    <span
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        lineHeight: '24px'
      }}
    >
      {/* {
          status === 'active' && (
            <span
              style={{
                display: 'inline-block',
                width: 24,
                height: 24,
                borderRadius: '50%',
                border: '2px solid #F2F3F5'
              }}
            />
          )
      } */}
      {
        status === 'success' && (
          <SuccessIcon/>
        )
      }
      {
        status === 'failed' && (
          <ErrorIcon/>
        )
      }
      {
        anchor && (
          <Anchor.Link
            href={`#${slug}`}
            title={name}
          />
        )
      }
      {
        !anchor && (
          <span
            style={{
              display: 'inline-block',
              marginLeft: 16,
              fontWeight: 700,
              color: status === 'active' ?
                '#9599A1'
                : undefined
            }}
          >{name}</span>
        )
      }
    </span>
  );
};
