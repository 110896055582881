import { ZoneMetadataSchema, ZoneMetadataValue } from './types';
import joi from 'joi';
import cloneDeep from 'lodash/cloneDeep';

export interface ValidationResult {
  fieldName: string;
  isValid: boolean;
  errorMessage?: string;
}

export type Validator = (value: ZoneMetadataValue) => ValidationResult;

export function getValidatorsMap(schema: ZoneMetadataSchema): Map<string, Validator> {
  const map = new Map<string, Validator>();

  const fields = Object.keys(schema);

  for (const field of fields) {
    const fieldSchema = cloneDeep(schema[field]);
    map.set(field, (value: ZoneMetadataValue) => validate(field, value, fieldSchema))
  }

  return map;
}


function validate(fieldName: string, value: ZoneMetadataValue, schema: ZoneMetadataSchema[string]): ValidationResult {
  if (schema.required) {
    if ((schema.type === 'drop_down' && schema.multi) || schema.type === 'tag_cloud') {
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return createValidationResult(fieldName, false, 'required')
        }
      }
    }

    if (value === null || value === '') {
      return createValidationResult(fieldName, false, 'required')
    }
  }

  if (schema.type === 'string' || schema.type === 'string_exact' || schema.type === 'text') {
    if (value !== null) {
      if (typeof value !== 'string') {
        return createValidationResult(fieldName, false, 'invalid value type');
      }
    }
  } else if (schema.type === 'boolean') {
    if (typeof value !== 'boolean') {
      return createValidationResult(fieldName, false, 'invalid value type');
    }
  } else if (schema.type === 'date') {
    if (value !== null) {
      if (typeof value !== 'string') {
        return createValidationResult(fieldName, false, 'invalid value type');
      }

      const matchTemplate = /\d{4}-\d{2}-\d{2}/.test(value);

      if (!matchTemplate) {
        return createValidationResult(fieldName, false, 'invalid date format, must be in YYYY-MM-DD');
      }

      const date = +new Date(value);

      if (isNaN(date)) {
        return createValidationResult(fieldName, false, 'invalid date value');
      }
    }
  } else if (schema.type === 'float' || schema.type === 'integer') {
    if (value !== null) {
      if (typeof value !== 'number') {
        return createValidationResult(fieldName, false, 'invalid value type');
      }

      if (schema.type === 'integer') {
        if (value % 1 !== 0) {
          return createValidationResult(fieldName, false, 'invalid integer value');
        }
      }

      if (schema.minValue !== undefined) {
        if (value < schema.minValue) {
          return createValidationResult(fieldName, false, 'less than ' + schema.minValue);
        }
      }

      if (schema.maxValue !== undefined) {
        if (value > schema.maxValue) {
          return createValidationResult(fieldName, false, 'greater than ' + schema.maxValue);
        }
      }
    }
  } else if (schema.type === 'url') {
    if (value !== null) {
      if (typeof value !== 'string') {
        return createValidationResult(fieldName, false, 'invalid value type');
      }

      const validator = joi.string().uri();
      const result = validator.validate(value);

      if (result.error) {
        return createValidationResult(fieldName, false, 'invalid URI value');
      }
    }
  } else if (schema.type === 'drop_down') {
    const allowedValue = schema.options!.map((o) => o.value);

    if (schema.multi) {
      if (!Array.isArray(value) || value.some((v) => typeof v !== 'string')) {
        return createValidationResult(fieldName, false, 'invalid value type');
      }

      if (value.some((v) => !allowedValue.includes(v))) {
        return createValidationResult(fieldName, false, 'one or more options is not allowed');
      }
    } else if (value !== null) {
      if (typeof value !== 'string') {
        return createValidationResult(fieldName, false, 'invalid value type');
      }

      if (!allowedValue.includes(value)) {
        return createValidationResult(fieldName, false, 'options is not allowed');
      }
    }
  } else if (schema.type === 'tag_cloud') {
    if (!Array.isArray(value) || value.some((v) => typeof v !== 'string')) {
      return createValidationResult(fieldName, false, 'invalid value type');
    }
  }

  return createValidationResult(fieldName, true);
}

function createValidationResult(fieldName: string, isValid: boolean, errorMessage?: string): ValidationResult {
  return {
    fieldName,
    isValid,
    errorMessage
  };
}
