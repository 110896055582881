import { Anchor, Space } from 'antd';
import React from 'react';
import { Step, StepProps } from './elements/Step';
import styles from './Steps.module.css';

type StepsProps = {
  anchor?: boolean;
  steps: StepProps[];
}

export const Steps: React.FC<StepsProps> = (props) => {
  if (props.anchor) {
    return (
      <Anchor affix={false} showInkInFixed={false} className={styles.anchor}>
        <Space
          direction="vertical"
          size={1}
          align="start"
        >
          {
            props.steps.map(step => (
              <Step
                key={step.name}
                {...step}
              />
            ))
          }
        </Space>
      </Anchor>
    );
  }
  return (
    <Space
      direction="vertical"
      size={24}
      align="start"
    >
      {
        props.steps.map(step => (
          <Step
            key={step.name}
            {...step}
          />
        ))
      }
    </Space>
  );
};
