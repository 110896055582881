import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React, { useCallback, useMemo } from 'react';
import { CustomFormItem } from '../../custom-form-item';
import { MetadataFormFieldProps } from '../types';


export const DateField: React.FC<MetadataFormFieldProps> = (props) => {
  const {
          onChange,
          value,
          disabled,
          label,
          validationStatus,
          required
        } = props;

  const internalOnChange = useCallback(
    (e: Moment | null, dateStr: string) =>
      onChange(e ? dateStr : null),
    [onChange],
  );

  const internalValue = useMemo<Moment | null>(
    () => value ?
      moment(value, 'YYYY-MM_DD')
      : null,
    [value],
  );

  return (
    <CustomFormItem
      label={label}
      validateStatus={validationStatus}
      required={required}
    >
      <DatePicker
        format="YYYY-MM-DD"
        disabled={disabled}
        onChange={internalOnChange}
        value={internalValue}
        allowClear
      />
    </CustomFormItem>
  );
};
