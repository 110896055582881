import { Select } from 'antd/';
import { SelectValue } from 'antd/lib/select';
import React, { useCallback, useMemo } from 'react';
import { CustomFormItem } from '../../custom-form-item';
import { MetadataFormFieldProps } from '../types';


export const DropdownField: React.FC<MetadataFormFieldProps> = (props) => {
  const {
          disabled,
          label,
          value,
          multi,
          validationStatus,
          onChange,
          options,
          required
        } = props;

  const internalOnChange = useCallback(
    (e: SelectValue) =>
      onChange(e),
    [onChange],
  );

  const internalOptions = useMemo(
    () => {
      if (options) {
        return options.map(option => (
          <Select.Option
            key={option.value}
            value={option.value}
          >{option.label}</Select.Option>
        ));
      }

      return [];
    },
    [options],
  );

  return (
    <CustomFormItem
      label={label}
      validateStatus={validationStatus}
      required={required}
    >
      <Select
        value={value}
        onChange={internalOnChange}
        mode={
          multi ?
            'multiple'
            : undefined
        }
        disabled={disabled}
        allowClear
      >
        {internalOptions}
      </Select>
    </CustomFormItem>
  );
};
