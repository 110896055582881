import axios, { AxiosError, AxiosInstance } from 'axios';
import { RequestCancelError, RequestError } from './errors';
import { StartAssetUploadQuery } from './requests-types';
import { ErrorDetails, ExpeDatConnectionSettings, PackageInfo, StartAssetUploadResult } from './response-types';

export class ApiClient {
  private axiosInstance!: AxiosInstance;
  private token!: string;

  constructor(
    customAxiosInstance?: AxiosInstance,
  ) {
    if (customAxiosInstance) {
      this.axiosInstance = customAxiosInstance;
    } else {
      this.axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
    }

    this.setupAuthInterceptor();
  }

  setToken(token: string) {
    this.token = token;
  }

  async getPackageInfo(): Promise<PackageInfo> {
    try {
      const response = await this.axiosInstance.get<PackageInfo>('/get-package-info');
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getExpeDatConnectionSettings(iconikStorageId: string): Promise<ExpeDatConnectionSettings> {
    try {
      const response = await this.axiosInstance.get<ExpeDatConnectionSettings>('/get-expedat-connection-settings', {
        params: {
          iconikStorageId
        }
      });
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async startAssetUpload(query: StartAssetUploadQuery): Promise<StartAssetUploadResult> {
    try {
      const response = await this.axiosInstance.post<StartAssetUploadResult>('/start-asset-upload', query);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async abortAssetUpload(assetId: string, isError: boolean, reason: string): Promise<void> {
    try {
      await this.axiosInstance.delete<void>('/abort-asset-upload/' + assetId, {
        params: {
          isError,
          reason,
        },
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  async completeAssetUpload(assetId: string, etags?: string[]): Promise<void> {
    try {
      await this.axiosInstance.post<void>('/complete-asset-upload/' + assetId, {
        etags
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  private setupAuthInterceptor() {
    this.axiosInstance.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${this.token}`;
      return config;
    });
  }

  private handleError(error: AxiosError<ErrorDetails>): never {
    if (axios.isCancel(error)) {
      throw new RequestCancelError();
    }

    if (error.response) {
      const data = error.response.data;
      throw new RequestError(data.status, data.message, data.details);
    }
    throw new RequestError(-1, 'Unknown error');
  }
}
