import { injectable } from 'inversify';
import { action, computed, observable } from 'mobx';

@injectable()
export class GlobalErrorState {
  @observable errorMessage: string | undefined;
  @observable errorTitle: string | undefined;

  @computed
  get hasError() {
    return !!this.errorMessage;
  }

  @action.bound
  setError(message: string, title?: string) {
    this.errorMessage = message;
    this.errorTitle = title;
  }

  @action.bound
  set404Error() {
    this.errorTitle = 'Page Not Found';
    this.errorMessage = 'The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.';
  }

  @action.bound
  set401Error() {
    this.errorTitle = 'Unauthorized';
    this.errorMessage = 'Sorry, your request could not be processed.';
  }

  @action.bound
  clearError() {
    this.errorMessage = undefined;
    this.errorTitle = undefined;
  }
}
