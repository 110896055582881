import React, { useMemo } from 'react';
import styles from './ContentZoneSubtitle.module.css';

type ContentZoneSubtitleProps = {
  name: string;
  allowFolders?: boolean;
  extensions?: string[];
  allowExtensions?: string[];
  excludeExtensions?: string[];
  maxFiles?: number;
}

export const ContentZoneSubtitle: React.FC<ContentZoneSubtitleProps> = (props) => {
  const {
          allowFolders,
          maxFiles,
          extensions,
          allowExtensions,
          excludeExtensions,
          name
        } = props;

  const str = useMemo<string>(
    () => {
      let result = 'Please upload ';

      if (maxFiles === 1) {
        result += `your ${name} asset.`;
      } else {
        result += `all your ${name} assets.`;
      }

      if (maxFiles === 1) {
        if (excludeExtensions && excludeExtensions.length !== 0) {
          result += ` It can be any file type except ${excludeExtensions.join(', ')} format.`;
        } else if (extensions && extensions.length !== 0) {
          result += ` It can be in ${extensions.join(', ')} format.`
        } else if (allowExtensions && allowExtensions.length !== 0) {
          result += ` It can be in ${allowExtensions.join(', ')} format.`
        } else {
          result += ` It can be of any format.`
        }
      }

      if (allowFolders) {
        result += ' You can drag and drop an entire folder or upload separately.';
      }

      result += ' You need to provide metadata information for';

      if (maxFiles === 1) {
        result += ` the uploaded `;
      } else {
        result += ` uploaded `;
      }

      result += 'file';

      if (maxFiles !== 1) {
        result += 's';
      }

      result += '.';

      return result;
    },
    [
      name,
      allowFolders,
      maxFiles,
      allowExtensions,
      extensions,
      excludeExtensions
    ]
  );

  return (
    <p className={styles.root}>{str}</p>
  );
};
