import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import {configure} from "mobx";
import {enableLogging} from 'mobx-logger';
import 'antd/dist/antd.less';
import './theme-vars.less';
import './antd-theme.less';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {globalIOCContainer} from './ioc/container';
import {buildContainer} from './ioc/build';

configure({
    enforceActions: 'observed'
});

if (process.env.NODE_ENV === 'development') {
  enableLogging();
}

buildContainer(globalIOCContainer);

ReactDOM.render(
    <React.StrictMode>
        <App
            container={globalIOCContainer}
        />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
