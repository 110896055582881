import { Modal } from 'antd';
import classNames from 'classnames';
import { Icon } from '../../elements/icon/Icon';
import { mergeWithSharedConfig, SharedProps } from '../../shared';
import { ReactComponent as SVGIcon } from './elements/icon.svg';
import commonStyles from '../../styles.module.css';

export type SuccessPopupProps = {
  message?: string;
} & SharedProps;

export function successPopup(props?: SuccessPopupProps) {
  return Modal.success(mergeWithSharedConfig({
    title: props?.title ?? 'Success',
    content: props?.message ?? null,
    autoFocusButton: 'ok',
    className: classNames(commonStyles.root),
    icon: <Icon><SVGIcon/></Icon>,
    onCancel: props?.onCancel,
    onOk: props?.onOk,
  }));
}
