import React from 'react';
import { Extension } from './elements/Extension';
import styles from './Preview.module.css';

type PreviewProps = {
  extension: string | null;
  preview?: string;
}

export const Preview: React.FC<PreviewProps> = (props) => {
  const {
          extension,
          preview,
        } = props;
  return (
    <span className={styles.root}>
      {
        !!preview && (
          <span className={styles.imgContainer}>
            <img src={preview} alt="Preview"/>
          </span>
        )
      }
      {
        !!extension && !preview && (
          <Extension
            extension={extension}
          />
        )
      }
    </span>
  );
};
