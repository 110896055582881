import {useContext} from 'react';
import {MobXProviderContext} from 'mobx-react';

export function assertUploadMethod(value: string, required: string): void | never {
    if (value !== required) {
        throw new TypeError(`Only ${required} upload method allowed for this action`);
    }
}

export function createProviderStateProps(state: any): object {
    return {
        states: state
    };
}

export function useMobXState<State>(id: symbol): State {
    return useContext(MobXProviderContext).states[id];
}
