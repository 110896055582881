import Icon from '@ant-design/icons';
import React from 'react';

const Source: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11" stroke="inherit" fill="transparent" strokeWidth="2"/>
    <path
      d="M10.8066 7.59729V5.09027C10.8066 4.43015 11.2153 4.1001 12.0328 4.1001C12.8504 4.1001 13.2591 4.4442 13.2591 5.1324V7.59729L12.8212 14.9498H11.2664L10.8066 7.59729ZM11.9891 19.1001C11.5365 19.1001 11.1715 18.9667 10.8942 18.6998C10.6314 18.433 10.5 18.1029 10.5 17.7096C10.5 17.3164 10.6387 16.9863 10.9161 16.7195C11.1934 16.4386 11.5511 16.2981 11.9891 16.2981C12.4416 16.2981 12.8066 16.4316 13.0839 16.6984C13.3613 16.9512 13.5 17.2743 13.5 17.6675C13.5 18.0608 13.3613 18.3979 13.0839 18.6788C12.8212 18.9596 12.4562 19.1001 11.9891 19.1001Z"
      fill="inherit"/>
  </svg>
);

export const ErrorIcon: React.FC = () => (
  <Icon
    component={Source}
    style={{
      stroke: '#FF723A',
      fill: '#FF723A'
    }}
  />
);
