import { inject, injectable } from 'inversify';
import { action, computed, observable, runInAction } from 'mobx';
import { GLOBAL_IOC_TYPES } from '../../ioc/iocTypes';
import { ApiClient } from '../../services/api-client/ApiClient';
import { ApiClientFactory } from '../../services/api-client/ApiClientFactory';
import { RequestError } from '../../services/api-client/errors';
import { PackageInfo } from '../../services/api-client/response-types';
import { GlobalErrorState } from '../GlobalErrorState';
import { ContentZonesState } from './content-zones/ContentZonesState';
import { UploadMethod, ZoneWebDat } from './content-zones/types';
import { WebDatClient } from '../../services/WebDat';
import { assertUploadMethod } from '../utils';

@injectable()
export class PackageState {
  private apiClient: ApiClient;
  @observable packageInfo!: PackageInfo;
  @observable contentZones!: ContentZonesState;
  @observable isPackageLoaded: boolean = false;
  @observable isPackageLoading: boolean = false;
  @observable isSubmittingStarted: boolean = false;
  @observable isDefaultUploadMethodUsed: boolean = true;
  @observable uploadMethod: UploadMethod = 'S3';

  @computed
  get isAbleToStartSubmitting(): boolean {
    if (!this.isPackageLoaded) {
      return false;
    }
    if (!this.isSubmittingStarted) {
      return this.contentZones.isAbleToStartUploading;
    }
    return true;
  }

  @computed
  get isSubmittingCompleted(): boolean {
    if (!this.isPackageLoaded) {
      return false;
    }
    if (this.isSubmittingStarted) {
      if (this.contentZones.totalAssets !== 0) {
        return this.contentZones.isAllAssetsCompleted;
      }
    }
    return false;
  }

  constructor(
    @inject(GLOBAL_IOC_TYPES.services.ApiClientFactory) private apiClientFactory: ApiClientFactory,
    @inject(GLOBAL_IOC_TYPES.services.WebDatClient) private webDatClient: WebDatClient,
    @inject(GLOBAL_IOC_TYPES.states.GlobalError) private globalErrorState: GlobalErrorState,
  ) {
    this.apiClient = apiClientFactory.createApiClient();
  }

  // load package stage using a package token
  // reset previously state if has
  load(token: string) {
    if (this.isPackageLoading) {
      return;
    }

    this.apiClient.setToken(token);

    runInAction(() => {
      this.isPackageLoading = true;
      this.isPackageLoaded = false;
    });

    (async () => {
      try {
        await this.cancelSubmitting();

        const packageInfo = await this.apiClient.getPackageInfo();
        const webDatAvailable = packageInfo.defaultUploadMethod === 'WebDat'
          ? await this.isWebDatClientAvailable()
          : false;

        runInAction(() => {
          this.packageInfo = packageInfo;
          if (packageInfo.defaultUploadMethod === 'WebDat') {
            if (webDatAvailable) {
              this.uploadMethod = 'WebDat';
            }
          }
          if (this.contentZones) {
            this.contentZones.destroyed = true;
          }
          this.isDefaultUploadMethodUsed = this.uploadMethod === packageInfo.defaultUploadMethod;
          this.contentZones = new ContentZonesState(this.apiClient, this.webDatClient, this.packageInfo, this.uploadMethod);
          this.isPackageLoaded = true;
          this.isPackageLoading = false;
        });
      } catch (error) {
        runInAction(() => {
          this.isPackageLoaded = false;
          this.isPackageLoading = false;
        });

        if (error instanceof RequestError) {
          if (error.statusCode === 404) {
            this.globalErrorState.set404Error();
          } else if (error.statusCode === 401) {
            this.globalErrorState.set401Error();
          } else {
            this.globalErrorState.setError(error.message);
          }
        } else {
          this.globalErrorState.setError('Something went wrong');
        }
      }
    })();
  }

  // abort all current uploading assets
  cancelSubmitting(returnPromise: false): void;
  cancelSubmitting(returnPromise?: true): Promise<void>;
  cancelSubmitting(returnPromise = true): Promise<void> | void {
    let promise = Promise.resolve();

    if (this.uploadMethod === 'S3') {
      promise = promise.then(() => {
        if (!this.isSubmittingStarted) {
          return;
        }
        if (this.isSubmittingCompleted) {
          return;
        }
        return this.contentZones
          .S3_abortUploadingAssets(false, 'Canceled')
          .then(() => runInAction(() => {
            this.isSubmittingStarted = false;
            this.contentZones.S3_setAbilityToAddAssets(true);
            this.contentZones.setAbilityToEditMetadata(true);
            this.contentZones.resetMetadataValidation();
          }));
        }
      );
    } else if (this.uploadMethod === 'WebDat') {
      promise = promise.then(() => this.contentZones.WebDat_abortAllActive());
      promise = promise.finally(() => {
        this.contentZones.setAbilityToEditMetadata(true);
        this.contentZones.resetMetadataValidation();
      })
    }

    if (returnPromise) {
      return promise;
    }

    promise.catch(() => {});
  }

  // finish submitting package
  @action.bound
  S3_finishSubmitting() {
    assertUploadMethod(this.uploadMethod, 'S3');

    if (!this.isSubmittingStarted) {
      return;
    }

    if (!this.isSubmittingCompleted) {
      return;
    }

    this.isSubmittingStarted = false;
    if (this.contentZones) {
      this.contentZones.destroyed = true;
    }
    this.contentZones = new ContentZonesState(this.apiClient, this.webDatClient, this.packageInfo, this.uploadMethod);
  }

  async changeUploadMethod(uploadMethod: UploadMethod) {
    if (this.uploadMethod === 'S3') {
      await this.cancelSubmitting(true);
    } else if (this.uploadMethod === 'WebDat') {
      await this.contentZones.WebDat_abortAllActive();
    }
    runInAction(() => {
      this.isSubmittingStarted = false;
      this.uploadMethod = uploadMethod;
      if (this.contentZones) {
        this.contentZones.destroyed = true;
      }
      this.contentZones = new ContentZonesState(this.apiClient, this.webDatClient, this.packageInfo, this.uploadMethod, this.contentZones);
    });
  }

  isInProgress(): boolean {
    if (this.uploadMethod === 'S3') {
      return this.isSubmittingStarted;
    }
    if (this.uploadMethod === 'WebDat') {
      return (Object.values(this.contentZones.zonesMap) as ZoneWebDat[]).some(z => z.status === 'starting' || z.status === 'uploading' || z.status === 'aborting');
    }
    return false;
  }

  // submit package assets and metadata
  @action.bound
  S3_submit() {
    assertUploadMethod(this.uploadMethod, 'S3');

    if (this.isSubmittingCompleted) {
      return;
    }

    if (!this.isAbleToStartSubmitting) {
      return;
    }

    if (!this.isSubmittingStarted) {
      this.contentZones.validateMetadata();

      if (this.contentZones.doesMetadataHasValidationError()) {
        return;
      }

      this.isSubmittingStarted = true;
      this.contentZones.S3_setAbilityToAddAssets(false);
      this.contentZones.setAbilityToEditMetadata(false);
    }

    this.contentZones.S3_startAssetsUploading();
  }

  async isWebDatClientAvailable() {
    return !!(await this.webDatClient.id());
  }
}
