import { Card, Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useMemo } from 'react';
import _slug from 'slug';
import { MetadataForm, MetadataFormProps } from '../metadata-form/MetadataForm';
import { UploaderSubmit, UploaderSubmitProps } from '../uploader-submit/UploaderSubmit';
import { Uploader, UploaderProps } from '../uploader/Uploader';
import { ContentZoneSubtitle } from './elements/content-zone-subtitle/ContentZoneSubtitle';

export type ContentZoneProps = {
  name: string;
  uploaderType?: 'browser' | 'submit';
  uploader: UploaderProps | UploaderSubmitProps;
  metadataForm: MetadataFormProps;
}

export const ContentZone: React.FC<ContentZoneProps> = (props) => {
  const {
          name, uploader, uploaderType, metadataForm,
        } = props;

  const showForm = useMemo(
    () => metadataForm.fields.length > 0,
      [metadataForm]
  );

  const slug: string = useMemo(() => _slug(name), [name]);

  return (
    <section>
      <Title level={3} id={slug}>{name}</Title>
      <ContentZoneSubtitle
        name={name}
        maxFiles={uploader.maxFiles}
        allowFolders={uploader.allowFolders}
      />
      <div style={{height: 36}}/>
      <Row gutter={36} wrap={false}>
        {
          showForm && (
            <Col flex="1">
              <Card
                bordered={false}
              >
                <MetadataForm {...metadataForm}/>
              </Card>
            </Col>
          )
        }
        <Col flex="1">
          <Card
            bordered={false}
            bodyStyle={{
              padding: 12
            }}
          >
            <UploaderType
              uploaderType={uploaderType}
              uploader={uploader}
            />
          </Card>
        </Col>
      </Row>
    </section>
  );
};

function UploaderType(props: {uploaderType?: 'browser' | 'submit' | undefined; uploader: UploaderProps | UploaderSubmitProps}) {
  if (props.uploaderType === 'browser' || !props.uploaderType) {
    return <Uploader {...props.uploader as UploaderProps}/>;
  } else if (props.uploaderType === 'submit') {
    return <UploaderSubmit {...props.uploader as UploaderSubmitProps}/>;
  }
  return null;
}
